@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";

$circle-width: 32px;

%active-page {
  background-color: $cerulean;
  border-color: $cerulean;

  color: $white;
}

%disabled-page {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 16px;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    justify-content: center;
  }

  @include side-spacing(margin, left, auto);

  &__message,
  &__controls {
    flex: 0 0 auto;
  }

  &__message {
    display: flex;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 16px;
    }
  }

  &__rows-select {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .select-wrapper,
  .native-select {
    @include side-spacing(margin, left, 12px);
    @include side-spacing(margin, right, 8px);
  }

  &__controls {
    display: flex;

    .pages {
      margin: 0 8px;
      //max-width: $circle-width * 4 + 24;

      overflow: hidden;

      &__container {
        display: flex;
        transition: all ease-out 0.3s;
        @include side-spacing(margin, left, 0);
      }

      .page,
      .spread {
        flex: 0 0 $circle-width;

        &:not(:last-of-type) {
          @include side-spacing(margin, right, 8px);
        }
      }
    }

    .prev,
    .next,
    .page,
    .spread {
      width: $circle-width;
      max-width: $circle-width;
      height: $circle-width;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .prev,
    .next,
    .page {
      border: 1px solid $gray-chateau;
      border-radius: 50%;
      cursor: pointer;

      &:not(.active):not(:disabled):hover {
        background-color: $gray-chateau;
      }
    }

    .prev,
    .next {
      &:disabled {
        @extend %disabled-page;
        color: inherit;
      }

      .rtl & {
        transform: scaleX(-1);
      }
    }

    .page {
      &.active {
        @extend %active-page;
      }
    }

    .next {
      @include side-spacing(margin, right, 3px);
    }
  }
}
