@import "../variables";

// Allows to set property values for every supported screen size
// Usage
//.button {
//  width: 10px; // xs size
//  @include adaptive(20px, 30%, 5em);
//}

@mixin adaptive($sm, $md, $lg, $property: "width") {
  @media (min-width: $screen-sm-min) {
    & {
      #{$property}: $sm;
    }
  }

  @media (min-width: $screen-md-min) {
    & {
      #{$property}: $md;
    }
  }

  @media (min-width: $screen-lg-min) {
    & {
      #{$property}: $lg;
    }
  }
}

// Shows an element after some point, similar to bootstrap's responsive utilities classes (visible-xs etc)
@mixin visible($point) {
  display: none;
  @media (min-width: $point) {
    & {
      display: initial;
    }
  }
}

// Hides an element after some point, similar to bootstrap's responsive utilities classes (hidden-xs etc)
@mixin hidden($point) {
  @media (min-width: $point) {
    & {
      display: none;
    }
  }
}
