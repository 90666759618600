@import "../eulaBanner/bannerColors";

:root {
  --eula-header-font-size: 16px;
  --eula-header-line-weight: bold;
  --eula-header-line-height: 22px;
  --eula-header-text-color: #fff;
  --eula-header-background: #{$eula-banner};
}

.eula-header {
  top: 0;
  font-size: var(--eula-header-font-size);
  font-weight: var(--eula-header-line-weight);
  line-height: var(--eula-header-line-height);
  color: var(--eula-header-text-color);
  background-color: var(--eula-header-background);
}
