@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/typography";

.select-wrapper {
  &--small {
    .select {
      &__button {
        height: 32px;
      }

      &__menu-wrapper {
        top: 36px;
      }
    }
  }

  &:not(&--small) {
    .select {
      &__button {
        height: 40px;
      }

      &__menu-wrapper {
        top: 44px;
      }
    }
  }

  .select {
    &.select--opened {
      .select__button {
        border-color: var(--color-primary);

        &-arrow {
          transform: rotateX(180deg);
        }
      }
    }

    &.select--disabled {
      .select__button {
        &-arrow:after {
          opacity: 0.3;
        }
      }
    }

    &__label:not(.select__label--hidden) {
      @extend .text-label;
    }

    &__button {
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid var(--color-controls-border);
      padding: 10px 16px;

      &:hover {
        border-color: var(--color-controls-border-hover);
      }

      &-arrow:after {
        color: var(--color-secondary);
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "\f107";
      }
    }

    &__menu-wrapper {
      z-index: $zindex-dropdown;
    }

    &-menu {
      border-radius: 3px;
      border: 1px solid var(--color-controls-border);
      box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.16);
      padding: 4px 0;
      overflow-y: auto;
      max-height: 202px; // 6 elements x 32px + 2 paddings x 4px + 2 borders x 1px
      outline-color: var(--color-primary);

      &__item {
        padding: 5px 16px;

        &--selected {
          background-color: var(--color-control-hover);
        }

        &--highlighted:not(.select-menu__item--selected) {
          background-color: var(--color-control-hover);

          &:hover {
            background-color: var(--color-select-item-hover);
          }
        }

        &--disabled {
          opacity: 0.65;
        }
      }
    }
  }
}
