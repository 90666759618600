@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/typography";

.list {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $porcelain;
    padding: 14px 8px 12px;
    margin-bottom: 16px;

    &__title {
      @include text-highlighted;
    }
  }

  &-content {
    @include side-spacing(margin, left, 8px);

    &__placeholder {
      color: $gray-chateau;
      font-style: italic;
    }
  }
}
