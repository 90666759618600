@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/typography";

.button-link {
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  color: var(--color-primary);
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    color: var(--color-primary-hover);
  }

  &[disabled] {
    color: var(--color-primary-disabled);
    cursor: not-allowed;
  }

  &--danger {
    @extend .color-danger;
  }

  &--inherit {
    color: inherit;
  }

  .icon {
    @include side-spacing(margin, right, 4px);
  }

  & + & {
    @include side-spacing(margin, left, 16px);
  }
}
