@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";

.time-picker-wrapper {
  display: flex;
  align-items: center;

  .select-wrapper {
    @include side-spacing(margin, left, 8px);

    .select__button {
      padding-left: 8px;
      padding-right: 8px;

      &-arrow {
        @include side-spacing(margin, left, 8px);
      }
    }
  }
}

.time-picker {
  position: relative;
  display: inline-block;
  background-color: $white;
  padding: 6px 8px;
  border: 1px solid $geyser;
  border-radius: 3px;
  line-height: initial;

  &:not(&--focused):hover {
    border-color: $gray-chateau;
  }

  &--focused {
    border: 1px solid var(--color-primary);
  }

  &__input {
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border: none;
    top: -10px;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }

  &__input-value,
  &__divider {
    display: inline-block;
    text-align: center;
    background-color: $white;
    border: none;
    @include text-uppercase;
  }

  &__divider {
    padding: 0 4px;
  }

  &__input:focus + &__input-value,
  &__input:active + &__input-value {
    background-color: var(--color-text-highlight);
  }
}
