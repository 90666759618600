@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/layout";
@import "./HelpPageSidebar/sidebar";

.help-page {
  @media (min-width: $screen-sm-min) {
    display: flex;
  }

  $content-padding-lg: 62px;
  $content-padding-md: 25px;

  &__sidebar {
    flex-shrink: 0;
    @include sidebar-width;
  }

  @media (max-width: $screen-xs-max) {
    .help-search {
      padding: 18px 15px;
      left: 0;
      right: 0;
      background-color: $white;
      border-bottom: 1px solid $porcelain;
      z-index: 1;
    }
  }

  &__content {
    overflow-x: hidden;
    color: $gray-chateau;

    .ltr & {
      @include adaptive(
        $content-padding-md,
        $content-padding-md,
        $content-padding-lg,
        "padding-left"
      );
    }

    .rtl & {
      @include adaptive(
        $content-padding-md,
        $content-padding-md,
        $content-padding-lg,
        "padding-right"
      );
    }

    .help-section:last-child {
      // Fixes the case when the last section's height is small so it cannot reach the top of the window
      // so react-scroll does not mark the section as active
      min-height: calc(100vh - 325px);
    }
  }

  &__error {
    color: var(--color-danger);
  }
}
