@import "../../../styles/mixins/rtl";
@import "../../../styles/variables";

.eula-banner {
  position: fixed;
  @include side-position(left, 0);
  z-index: $zindex-eula-banner;
  min-height: 30px;
  width: 100%;
  padding: 7px 25px;
  text-align: center;
  word-break: break-word;
}
