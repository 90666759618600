@import "../../../../styles/colors";

.no-devices {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__icon {
    font-size: 5em;
    color: $porcelain;
  }

  &__message {
    margin-bottom: 16px;
  }
}
