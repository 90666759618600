@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/variables";

@mixin flip-switch(
  $width,
  $height,
  $border-width,
  $flap-width,
  $gap-vertical,
  $gap-horizontal
) {
  display: inline-block;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;

  &__label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    border: $border-width solid transparent;
    width: $width;
    height: $height;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;

    [data-type="on"],
    [data-type="off"],
    &:after {
      position: absolute;
      height: $height - 2 * $border-width;
      line-height: $height - 2 * $border-width;
      display: block;
      text-align: center;
      transition: all 0.3s ease;
      @include text-uppercase;
    }

    [data-type="on"],
    [data-type="off"] {
      top: 0;
      width: $width;
    }

    &_on [data-type="on"] {
      @include side-spacing(
        padding,
        right,
        $flap-width + $gap-horizontal + 1px
      );
    }

    &:after {
      content: "";
      width: $flap-width;
      border-style: solid;
      border-width: 1px;
      top: $gap-vertical;
      height: $height - (2 * $border-width) - (2 * $gap-vertical);
    }

    &_off [data-type="off"] {
      @include side-spacing(padding, left, $flap-width + $gap-horizontal - 5px);
    }
  }

  &__input {
    display: none;
  }

  &__input:not(:checked) + &__label {
    &:after {
      @include side-position(left, $gap-horizontal);
    }

    [data-type="on"] {
      @include side-position(left, -$width);
    }

    [data-type="off"] {
      @include side-position(left, -$border-width);
    }
  }

  &__input:checked + &__label {
    &:after {
      @include side-position(
        left,
        $width - $flap-width - $gap-horizontal - 2 * $border-width
      );
    }

    [data-type="on"] {
      @include side-position(left, 0);
    }

    [data-type="off"] {
      @include side-position(left, $width);
    }
  }

  &__input:disabled ~ &__label {
    &_on [data-type="on"] {
      color: $geyser;
      &,
      &:hover {
        background-color: $gray-chateau;
      }
    }
    &_off [data-type="off"] {
      color: $boulder;
      &,
      &:hover {
        background-color: $porcelain;
      }
    }
  }
}

@mixin flip-switch-color(
  $on-color,
  $on-bg-color,
  $off-color,
  $off-bg-color,
  $flap-color,
  $on-hover-bg-color: null,
  $off-hover-bg-color: null
) {
  .flip-switch {
    &__label {
      &_on [data-type="on"] {
        color: $on-color;
        background-color: $on-bg-color;

        &:hover {
          background-color: if(
            $on-hover-bg-color,
            $on-hover-bg-color,
            darken($on-bg-color, 12%)
          );
        }
      }

      &:after {
        border-color: $flap-color;
        background-color: $flap-color;
      }

      &_off [data-type="off"] {
        color: $off-color;
        background-color: $off-bg-color;

        &:hover {
          background-color: if(
            $off-hover-bg-color,
            $off-hover-bg-color,
            darken($off-bg-color, 12%)
          );
        }
      }
    }

    &__input:checked + &__label {
      &:after {
        border-color: darken($on-bg-color, 5%);
        background-color: $flap-color;
      }
    }
  }
}

@mixin flip-switch-border-radius($border-radius, $flap-border-radius) {
  &__label {
    border-radius: $border-radius;
    z-index: 5;

    &:after {
      border-radius: $flap-border-radius;
    }
  }
}

@mixin flip-switch-font-weight($font-weight) {
  &__label {
    &_on,
    &_off,
    &:after {
      font-weight: $font-weight;
    }
  }
}

@mixin flip-switch-round-flap($width, $height, $border-width, $gap) {
  $flap-radius: $height / 2 - $border-width - $gap;
  $flap-width: $flap-radius * 2;

  @include flip-switch($width, $height, $border-width, $flap-width, $gap, $gap);
  @include flip-switch-border-radius($height / 2, $flap-radius);
}

.flip-switch {
  @include flip-switch-round-flap(58px, 24px, 0, 4px);
  @include flip-switch-font-weight(300);
}

.flip-switch_primary {
  @include flip-switch-color(
    $white,
    $brand-primary,
    $mine-shaft,
    $geyser,
    $white,
    $allports,
    $gray-chateau
  );
}

.flip-switch_text {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  @include side-spacing(margin, right, 8px);
}
