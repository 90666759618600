.unstyled-button {
  border: none;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  color: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: transparent;

  &[disabled] {
    color: var(--color-primary-disabled);
    cursor: not-allowed;
  }
}
