@import "../../styles/colors";
@import "../../styles/variables";

.profiles {
  flex: 1 0 100%; // TODO: extract

  &--multiple {
    @media (min-width: $screen-md-min) {
      height: calc(100% - 40px);
    }
  }

  &__title {
    &--single {
      margin-bottom: 16px;
    }
  }

  &__content {
    .web-filters__header,
    .internet-off__header {
      color: var(--color-primary);
      margin-bottom: 8px;
    }
  }

  &__section {
    @media (max-width: $screen-xs-max) {
      margin-top: 24px;
    }
    margin-bottom: 24px;
  }

  .sticky-sidebar__header {
    margin-bottom: 8px;
  }

  .sidebar__header--main {
    margin-bottom: 20px;
  }

  .sidebar__add-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar__content {
    .web-filters {
      margin-top: 60px;
    }
  }
}
