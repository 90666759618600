@import "../../styles/mixins/rtl";
@import "../../styles/colors";
@import "../../styles/variables";

.spson-page {
  &__title {
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 16px;
  }

  &__content {
    display: flex;

    &--left {
      flex: auto;
      @include side-spacing(margin, right, 24px);
    }

    &--right {
      flex: none;
    }
  }

  @media (max-width: $screen-sm-max) {
    &__title {
      display: none;
    }

    &__content {
      margin-top: 16px;
      flex-direction: column;
    }
  }
}
