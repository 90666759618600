@import "../../../../../styles/colors";
@import "../../../../../styles/mixins/rtl";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/typography";

.schedule-rule-hint {
  display: flex;
  align-items: flex-start;
  max-width: fit-content;

  &__icon {
    color: var(--color-primary);
    margin-top: 2px;
    @include side-spacing(margin, right, 8px);
  }

  &__note {
    color: $mid-gray;
    @include font-size(small);
    text-align: justify;
  }
}
