@import "../../styles/colors";
@import "../../styles/mixins/typography";

.user-settings-page {
  &__header {
    margin-bottom: 16px;
  }

  .select {
    margin-bottom: 16px;
  }

  .time-section,
  .language-section {
    margin-bottom: 40px;
  }
}
