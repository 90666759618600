@import "../../../styles/colors";
@import "../../../styles/mixins/rtl";
@import "../../../styles/variables";

.modal__header {
  position: relative;

  @media (min-width: $screen-sm-min) {
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    font-weight: 600;

    @media (min-width: $screen-sm-min) {
      margin: 0 32px;
    }
  }

  &__cross-button {
    color: $mid-gray;
    @include side-position(right, -6px);

    @media (max-width: $screen-xs-max) {
      position: relative;
    }

    @media (min-width: $screen-sm-min) {
      position: absolute;
    }
  }
}
