@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/typography";

body.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  display: block;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.7);
  z-index: $modal-layout;
  outline: none;

  @media (min-width: $screen-sm-min) {
    overflow-y: auto;
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $geyser;

    @media (min-width: $screen-sm-min) {
      position: relative;
      top: 12%;
      max-width: 460px;
      margin: 0 auto;
      padding: 24px 32px 32px;
      border-radius: 12px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    }

    @media (max-width: $screen-xs-max) {
      padding: 16px;
      height: 100%;
    }
  }

  &__header {
    display: flex;
    flex: 1 0 auto;
    align-items: center;

    @media (min-width: $screen-sm-min) {
      justify-content: center;
      align-items: center;
    }

    @media (max-width: $screen-xs-max) {
      justify-content: space-between;
    }
  }

  &__body {
    flex: 1 1 100%;

    @media (min-width: $screen-sm-min) {
      min-height: 0;
      margin-top: 24px;
      margin-bottom: 32px;
    }

    @media (max-width: $screen-xs-max) {
      overflow-y: auto;
      margin: 16px 0;
    }
  }

  &__footer {
    display: flex;
    flex: 1 0 auto;

    @media (max-width: $screen-xs-max) {
      .modal__control {
        flex: 1;
      }
    }

    @media (min-width: $screen-sm-min) {
      justify-content: flex-end;
    }
  }
}

.actions-modal {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 288px;
  border-radius: 12px;
  padding: 0;
  box-shadow: 0 -1px 1px 0 rgba(152, 152, 152, 0.21);

  .modal__header {
    justify-content: center;
    padding: 12px 24px;
    border-bottom: 1px solid $porcelain;

    .header-text {
      @include font-size(regular);
      @include font-weight(semi-bold);
      text-align: center;
      margin-bottom: 0;
      word-break: break-word;
    }
  }

  .modal__body {
    padding: 8px 24px;
    text-align: left;
  }

  .modal__footer {
    border-top: 1px solid $porcelain;

    .dismiss-button {
      padding: 8px 24px;
    }
  }
}
