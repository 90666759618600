@import "../../styles/colors";
@import "../../styles/variables";

.scheduled-reports-page {
  &__header {
    margin-bottom: 16px;
  }

  .page-description {
    margin-bottom: 16px;
  }

  .input.has-error .input__error {
    margin-top: 0;
  }

  .scheduled-reports-form {
    margin-bottom: 32px;
  }

  @media (max-width: $screen-xs-max) {
    .scheduled-reports-form {
      .col-12:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .select-wrapper {
      max-width: 200px;
    }
  }
}
