@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/typography";

.tooltip {
  @include font-size(small);
  padding: 4px 8px;
  border-radius: 3px;
  z-index: $zindex-tooltip;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  &:not(.tooltip--auto-width) {
    max-width: 300px;
  }

  &--light {
    border: 1px solid $geyser;
    background-color: $white;
    color: $mid-gray;

    .tooltip__arrow:before {
      background-color: $white;
      border-color: $geyser;
    }
  }

  &--dark {
    border: 1px solid $mine-shaft;
    background-color: $mine-shaft;
    color: $white;

    .tooltip__arrow:before {
      background-color: $mine-shaft;
      border-color: $mine-shaft;
    }

    .tooltip__content {
      font-weight: 600;
    }
  }

  &__content {
    text-align: center;
  }

  .tooltip__arrow:before {
    border-style: solid;
    border-width: 0;
  }

  &[data-popper-placement^="top"] {
    .tooltip__arrow {
      &:before {
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
        border-bottom-width: 1px;
        border-right-width: 1px;
      }
    }
  }

  &[data-popper-placement^="bottom"] {
    .tooltip__arrow {
      &:before {
        box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.16);
        border-top-width: 1px;
        border-left-width: 1px;
      }
    }
  }

  &[data-popper-placement^="left"] {
    .tooltip__arrow {
      &:before {
        box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.16);
        border-top-width: 1px;
        border-right-width: 1px;
      }
    }
  }

  &[data-popper-placement^="right"] {
    .tooltip__arrow {
      &:before {
        box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.16);
        border-bottom-width: 1px;
        border-left-width: 1px;
      }
    }
  }
}
