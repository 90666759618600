@mixin create-button-variant(
  $color,
  $bg-color,
  $hover-bg-color,
  $active-bg-color,
  $disabled-bg-color
) {
  color: $color;
  background-color: $bg-color;
  border-color: $bg-color;
  letter-spacing: -0.3px;
  cursor: pointer;

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: if(
        $hover-bg-color,
        $hover-bg-color,
        darken($bg-color, 10%)
      );
      border-color: if(
        $hover-bg-color,
        $hover-bg-color,
        darken($bg-color, 10%)
      );
    }
  }
  &:active {
    &:not(:disabled) {
      background-color: if(
        $active-bg-color,
        $active-bg-color,
        darken($bg-color, 20%)
      );
      border-color: if(
        $active-bg-color,
        $active-bg-color,
        darken($bg-color, 20%)
      );
    }
  }
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    background-color: if(
      $disabled-bg-color,
      $disabled-bg-color,
      lighten($bg-color, 20%)
    );
    border-color: if(
      $disabled-bg-color,
      $disabled-bg-color,
      lighten($bg-color, 20%)
    );
  }
}

@mixin create-outline-button-variant(
  $color,
  $bg-color,
  $border-color,
  $hover-bg-color,
  $active-bg-color
) {
  color: $color;
  background-color: $bg-color;
  border: 1px solid $border-color;
  letter-spacing: 0;
  cursor: pointer;

  &:focus,
  &:hover {
    &:not(:disabled) {
      border-color: $border-color;
      background-color: if(
        $hover-bg-color,
        $hover-bg-color,
        lighten($hover-bg-color, 30%)
      );
    }
  }
  &:active {
    &:not(:disabled) {
      border-color: $border-color;
      background-color: if(
        $active-bg-color,
        $active-bg-color,
        lighten($hover-bg-color, 20%)
      );
    }
  }
  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: not-allowed;
  }
}

@mixin create-pill-button-variant(
  $color,
  $bg-color,
  $border-color,
  $hover-bg-color,
  $active-bg-color
) {
  color: $color;
  background-color: $bg-color;
  border: 1px solid $border-color;
  letter-spacing: 0;
  cursor: pointer;

  &:focus,
  &:hover {
    &:not(:disabled) {
      border-color: $border-color;
      background-color: if(
        $hover-bg-color,
        $hover-bg-color,
        lighten($hover-bg-color, 30%)
      );
    }
  }
  &:active {
    &:not(:disabled) {
      border-color: $active-bg-color;
      background-color: if(
        $active-bg-color,
        $active-bg-color,
        lighten($hover-bg-color, 20%)
      );
    }
  }
  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: not-allowed;
  }
}

@mixin create-button($v-paddings, $h-paddings, $radius) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  font-size: 14px;
  line-height: 20px;

  border: 1px solid;
  border-radius: $radius;

  padding: $v-paddings $h-paddings;
}
