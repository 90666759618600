@import "../../../styles/colors";

.tab-control {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--color-main);
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid var(--color-primary);
  padding: 10px 16px;
  height: 40px;
  white-space: nowrap;

  &:hover {
    background-color: $white-ice;
    color: var(--color-main);
  }

  &:active {
    background-color: var(--color-primary-pressed);
    border-color: var(--color-primary-pressed);
    color: #fff;
  }

  &#{&}--disabled {
    cursor: not-allowed;
  }

  &:not(&--selected) {
    &.tab-control--disabled {
      color: var(--color-main);
      background-color: #fff;
      opacity: 0.6;
    }
  }

  &:active,
  &#{&}--selected {
    outline: none;
  }

  &#{&}--selected {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #fff;
    pointer-events: none;

    &.tab-control--disabled {
      background-color: var(--color-primary-disabled);
      border-color: var(--color-primary-disabled);
      color: #fff;
    }
  }
}
