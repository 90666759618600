@import "../../../styles/colors";
@import "../../../styles/mixins/rtl";

.hidden-options {
  width: 80px;
  @include side-spacing(margin, left, 8px);

  &:not(&--selected) {
    .select {
      &__button-content:after {
        content: "...";
        display: block;
      }
    }
  }

  &.hidden-options--selected {
    .select {
      &--opened {
        .select__button {
          background-color: var(--color-primary-pressed);
          border-color: var(--color-primary-pressed);
        }
      }

      &__button {
        color: #fff;
        background-color: var(--color-primary);
        border-color: var(--color-primary);

        &:hover {
          background-color: var(--color-primary-pressed);
          border-color: var(--color-primary-pressed);
        }

        &-arrow:after {
          color: #fff;
        }
      }
    }
  }

  .select {
    &--opened {
      .select__button {
        border-color: var(--color-primary);
        background-color: var(--color-control-hover);
      }
    }

    &__menu-wrapper {
      @include side-position(right, 0);
      min-width: 100%;
      width: max-content;
    }

    &__button {
      border-color: var(--color-primary);

      &:hover {
        border-color: var(--color-primary);
        background-color: var(--color-control-hover);
      }

      &-arrow {
        @include side-spacing(margin, left, 8px);
      }
    }
  }
}
