@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";

.side-menu {
  &:before {
    content: "";
    position: fixed;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $mine-shaft;
    z-index: $zindex-mobile-menu-backdrop;
    opacity: 0;
    transition: opacity 0.3s ease, width 0.3s step-end;
  }

  &--open {
    &:before {
      width: 100%;
      opacity: 0.6;
      transition: opacity 0.3s ease, width 0.3s step-start;
    }
  }
}

.menu {
  z-index: $zindex-mobile-menu;
  position: fixed;
  top: 0;
  height: 100%;
  min-width: 260px;
  width: 85%;
  overflow-y: auto;
  background-color: $white;
  transition: transform 0.3s ease;
  @include side-position(left, -100%);

  @media (orientation: landscape), (min-width: $screen-sm-min) {
    width: 50%;
  }

  &__content {
    padding-top: 8px;
  }

  &--open {
    transform: translateX(-100%);
    @include side-position(left, 100%);

    .rtl & {
      transform: translateX(100%);
    }
  }

  .close-button {
    position: absolute;
    top: 15px;
    @include side-position(right, 10px);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $mid-gray;
    z-index: $navbar-layout;
  }

  .divider {
    margin: 8px 16px;
    border-bottom: 1px solid $porcelain;
  }

  &__item,
  &__sub-menu,
  &__action-item {
    display: block;
    width: calc(100% - 32px);
    transition-property: all;
    transition-duration: 200ms;
    color: $mine-shaft;
    @include ellipsis;
    @include text-align(left);

    -webkit-tap-highlight-color: transparent;
  }

  &__item,
  &__sub-menu,
  &__action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 16px;
    padding: 6px 8px;
    border-radius: 3px;
    background-color: $porcelain;
    cursor: pointer;

    &:hover {
      color: inherit;
    }

    &--active {
      background-color: var(--color-primary);
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  &__sub-menu,
  &__user-menu {
    & ~ ul {
      display: none;

      .menu {
        &__item,
        &__action-item {
          background-color: inherit;

          &--active {
            background-color: $cerulean;
          }
        }
      }
    }

    &--open ~ ul {
      display: block;
    }
  }

  &__sub-menu {
    &--highlighted {
      background-color: $cerulean;
      color: $white;
    }

    .icon {
      font-size: 10px;
    }
  }

  &__user-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $porcelain;
    border-top: 1px solid $geyser;
    border-bottom: 1px solid $geyser;
    padding: 12px 16px;

    .account-info {
      @include text-caption;

      &__name {
        @include font-size(regular);
        margin-top: 1px;
        color: $cerulean;
      }
    }

    .toggle-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 32px;
      height: 32px;

      background-color: $cerulean;
      border-radius: 3px;
      color: $white;
    }

    & ~ ul {
      background-color: $black-haze;
      border-bottom: 1px solid $geyser;
      padding: 5px 0;

      li {
        padding: 5px 16px;
      }

      a,
      .menu__action-item {
        padding: 6px 8px;
        margin: 0;
        width: 100%;
        color: inherit;
      }
    }
  }
  .button-link--inherit {
    padding: 6px 16px;
  }

  .add-device-link {
    transition-property: all;
    transition-duration: 200ms;
    white-space: nowrap;
    color: $mine-shaft;

    &[disabled],
    &.disabled {
      color: $geyser;
    }
  }

  .add-device-link {
    width: 100%;
  }
}
