@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/mixins";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";

.table {
  margin-top: 8px;

  table {
    table-layout: fixed;

    width: 100%;
    color: $mine-shaft;

    border-radius: 3px;
    border-collapse: separate;

    @media (min-width: $screen-sm-min) {
      border: 1px solid $geyser;
    }
  }

  thead {
    background-color: $porcelain;
  }

  tbody {
    tr:not(.no-data-row):not(.sub-row) {
      &.--even,
      &.--even + .sub-row {
        background-color: $white;
      }

      &.--odd,
      &.--odd + .sub-row {
        background-color: $black-haze;
      }

      &:hover {
        background-color: $white-ice;
      }
    }
  }

  th,
  td {
    vertical-align: top;

    @include text-align(left);
    @include ellipsis;
  }

  td .device-name .tooltip-referrer {
    display: block;
    @include ellipsis;
  }

  tr:not(.sub-header-row) th,
  tr:not(.sub-row):not(.table-footer):not(.no-data-row) td {
    padding: 8px;

    @media (min-width: $screen-sm-min) {
      &:first-child {
        @include side-spacing(padding, left, 16px);
      }

      &:last-child {
        @include side-spacing(padding, right, 16px);
      }
    }
  }

  tr:not(.sub-header-row) th {
    font-weight: 600;
  }

  tr:not(.sub-row):not(.table-footer):not(.no-data-row) td {
    padding: 8px;

    @media (min-width: $screen-sm-min) {
      padding: 12px 8px;
    }
  }

  & &__cell--right {
    @include text-align(right);
  }

  & &__cell--center {
    @include text-align(center);
  }

  & &__cell--small-cell {
    width: 80px;
    @include ellipsis;
  }

  & &__cell--overflow {
    overflow: visible;
  }

  .table__cell--placeholder {
    color: $boulder;
  }

  .no-data-row {
    height: 74px;

    td {
      text-align: center;
      padding: 24px 16px;
      color: $boulder;
    }
  }

  .expand-row {
    cursor: pointer;
  }

  .sub-row td {
    padding: 8px 16px;
  }

  .sub-row-content {
    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, 24px);
    }

    &__header {
      font-weight: 600;
    }

    &__data {
      word-break: break-word;
      white-space: pre-line;
    }

    &__item {
      @include side-spacing(margin, right, 32px);
      margin-bottom: 8px;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .expand-row__expanded td:first-child,
  .expand-row__expanded + .sub-row td {
    @include side-spacing(border, left, 3px solid $cerulean);
  }

  .expand-row__expanded td:first-child {
    @include side-spacing(padding, left, 5px);
  }

  .icon {
    width: 16px;
  }
}

.sorted {
  position: relative;
  display: inline-block;

  @include side-spacing(padding, right, 12px);

  &:before,
  &:after {
    @include fa-icon();
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    visibility: visible;
    font-size: 14px;
    position: absolute;

    @include side-position(right, 0);

    color: $boulder;
    opacity: 0.5;
  }

  &:before {
    content: fa-content($fa-var-caret-up);
    bottom: 9px;
  }

  &:after {
    content: fa-content($fa-var-caret-down);
    top: 9px;
  }

  &--asc:before,
  &--desc:after {
    opacity: 1;
  }
}

.table__search {
  @media (min-width: $screen-sm-min) {
    @include float(right);
    width: auto;
    max-width: 400px;
  }

  &.input input {
    @include side-spacing(padding, left, 36px);
  }
}

.table__sub-header {
  @include font-size(small);
  padding: 0 8px;
  font-weight: 600;
  background-color: $geyser;
  color: $mid-gray;
  text-align: center;

  @media (max-width: $screen-sm-max) {
    padding: 4px 8px;
  }
}
