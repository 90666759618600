@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";

.threat-list {
  td,
  th {
    border: none;
    padding: 15px;
    vertical-align: middle;
  }

  thead {
    background-color: $porcelain;
  }

  th {
    text-transform: capitalize;
    @include text-align(left);
    font-size: 14px;
    white-space: nowrap;
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $black-haze;
      }

      &:hover {
        background-color: $porcelain;
      }
    }
  }
  @media (max-width: $screen-sm-max) {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: block;

      tr {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $black-haze;
      }

      td {
        padding-bottom: 12.5px;

        &:not(:last-child) {
          padding-top: 12.5px;
        }

        &:first-child,
        &:nth-child(2) {
          flex: 1 1 50%;
        }

        &:first-child {
          font-weight: 600;
        }

        &:nth-child(2) {
          display: flex;
          justify-content: flex-end;
        }

        &:last-child {
          flex: 1;
          padding-top: 0;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    tr:nth-child(odd) {
      background-color: $white;
    }
  }
}
