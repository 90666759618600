@import "../../styles/mixins/rtl";
@import "../../styles/variables";

.confirmation-dialog {
  @media (min-width: $screen-md-min) {
    &__footer {
      & > :first-child {
        @include side-spacing(margin, right, auto);
      }
    }
  }
}
