@import "../../../../styles/typography";

.unblock-device-modal {
  &__message {
    margin-bottom: 24px;
  }

  &__error {
    margin-top: 4px;
    @include text-caption;
  }
}
