@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";

.help-page-navigation {
  $active-tab-highlight-offset: 8px;

  &__list {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }

    &--nested {
      .help-page-navigation__link {
        font-weight: 400;
        @include side-spacing(padding, left, $active-tab-highlight-offset + 8);
      }
    }
  }

  &__link {
    flex: 1 1 auto;
    position: relative;
    color: $mine-shaft !important;
    line-height: 20px;
    padding: $active-tab-highlight-offset;
    margin-bottom: 8px;

    @media (min-width: $screen-sm-min) {
      border-radius: 3px;
    }

    &--active {
      background-color: var(--color-primary);
      color: $white !important;
    }

    &:hover:not(&--active) {
      background-color: $white-ice;
    }
  }
}
