.go-back-btn {
  .icon {
    font-size: 18px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }
}
