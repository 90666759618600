@import "../../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";
@import "../../../../node_modules/@fortawesome/fontawesome-pro/scss/mixins";
@import "../../../styles/colors";
@import "../../../styles/mixins/rtl";

.mobile-sorting__btn {
  border: none;
  background-color: transparent;
  color: $mine-shaft;
  font-weight: 600;
  margin-top: 16px;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.sorted {
    cursor: pointer;
  }
}

.sort-by-modal {
  .items__container {
    display: flex;
    flex-direction: column;

    > div + div {
      margin-top: 16px;
    }

    .unmodified__btn {
      @include side-spacing(padding, left, 24px);
      @include text-align(left);
    }
  }

  .sorted {
    &:before,
    &:after {
      @include side-position(left, 0);
      @include side-position(right, auto);
    }

    .unmodified__btn {
      font-weight: 600;
    }
  }

  .unmodified__btn {
    border: none;
    background-color: transparent;
    color: $mine-shaft;
    width: 100%;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }
}
