@import "../eulaBanner/bannerColors";

:root {
  --eula-footer-font-size: 16px;
  --eula-footer-line-weight: bold;
  --eula-footer-line-height: 22px;
  --eula-footer-text-color: #fff;
  --eula-footer-background: #{$eula-banner};
}

.eula-footer {
  bottom: 0;
  font-size: var(--eula-footer-font-size);
  font-weight: var(--eula-footer-line-weight);
  line-height: var(--eula-footer-line-height);
  color: var(--eula-footer-text-color);
  background-color: var(--eula-footer-background);
}
