@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";

.protection-choice-page {
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin: 0 auto 30px;
  }

  .image-container {
    margin: 100px 0 45px;

    &.profile-multiple-ico {
      width: 200px;

      .img-wrapper {
        border-radius: 50%;
        text-align: center;

        &:not(:last-child) {
          color: var(--color-primary);
          float: right;
          background-color: $porcelain;
          width: 70px;
          height: 70px;
          margin-top: 20px;
          padding-top: 20px;
          font-size: 27px;
        }

        &:first-child {
          float: left;
        }

        &:last-child {
          position: relative;
          width: 86px;
          height: 86px;
          padding-top: 25px;
          margin: auto;
          font-size: 35px;
          color: $white;
          background-color: var(--color-primary);
        }
      }
    }

    &.profile-single-ico {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      font-size: 31px;
      padding-top: 28px;
      color: $white;
      background-color: var(--color-primary);
      text-align: center;
    }
  }

  .description-container {
    min-height: 160px;
    width: 100%;

    .option-title {
      @include text-uppercase;
      margin-bottom: 24px;
      font-weight: 600;
    }

    .option-subtitle {
      color: var(--color-secondary);
      margin-bottom: 16px;
    }

    .option-description {
      margin: auto;
      width: 80%;
      line-height: 180%;
    }
  }

  .btn--primary {
    margin-top: 24px;
  }

  .divider {
    @media (max-width: $screen-xs-max) {
      width: 100%;
      height: 1px;
      background-color: $porcelain;
    }

    @media (min-width: $screen-sm-min) {
      position: absolute;
      left: 50%;
      right: 50%;
      top: 290px;
      width: 1px;
      height: 155px;
      background-color: $porcelain;
    }
  }
}
