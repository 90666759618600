@import "../colors";

// fonts-size
$size: (
  "pixie": 8px,
  "tiny": 10px,
  "small": 12px,
  "regular": 14px,
  "medium": 16px,
  "large": 18px,
  "x-large": 20px,
  "xx-large": 24px,
  "jumbo": 30px
);

//line-height
$line-height: (
  "pixie": 12px,
  "tiny": 16px,
  "small": 18px,
  "regular": 22px,
  "medium": 24px,
  "large": 28px,
  "x-large": 30px,
  "xx-large": 36px,
  "jumbo": 46px
);

// font-weights
$weights: (
  "light": 300,
  "regular": 400,
  "semi-bold": 600,
  "bold": 700,
  "extra-bold": 800
);

@mixin font-size($name) {
  font-size: map-get($size, $name);
  line-height: map-get($line-height, $name);
}

@mixin font-weight($name) {
  font-weight: map-get($weights, $name);
}

// transformations
@mixin text-uppercase {
  text-transform: uppercase;
}

@mixin text-capitalize {
  text-transform: capitalize;
}

@mixin text-italic {
  font-style: italic;
}

// types of text
@mixin text-main {
  @include font-size("regular");
  @include font-weight("regular");
  color: var(--color-main);
}

@mixin text-highlighted {
  @include font-weight("semi-bold");
}

@mixin text-disabled {
  @include text-main;
  color: var(--color-disabled);
}

@mixin text-label {
  @include font-size("small");
  @include font-weight("regular");
  color: var(--color-main);
}

@mixin text-caption {
  @include font-size("small");
  color: var(--color-disabled);
}

@mixin text-hint {
  @include font-size("regular");
  @include font-weight("regular");
  @include text-italic;
  color: var(--color-secondary);
}

@mixin text-error {
  @include font-size("small");
  @include font-weight("regular");
  color: var(--color-danger);
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
