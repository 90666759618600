@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";

.counter {
  display: flex;
  align-items: center;
  @include font-size(medium);

  &__label {
    font-weight: 600;
    @include side-spacing(margin, right, 8px);
  }

  &__amount {
    @include font-weight(light);
    color: $mid-gray;
    display: flex;
  }
}
