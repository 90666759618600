@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";
@import "../../styles/variables";

.deeplink-modal {
  .btn {
    width: 100%;
  }

  &__status {
    @include text-caption;
  }

  &__status &__icon {
    @include font-size(medium);
    @include side-spacing(margin, right, 8px);
  }

  &__text {
    margin: 16px auto 0;
  }

  &__copy-with-instructions {
    margin-top: 16px;
    padding: 12px 24px;
    background-color: $black-haze;
    text-align: center;

    .button-link .icon-copy {
      @include side-spacing(margin, right, 10px);
    }

    &__text {
      @include font-size(pixie);
      margin-top: 8px;
      color: $mid-gray;
    }

    b {
      display: block;
      font-weight: 600;
    }
  }

  &--success {
    .deeplink-modal__status .deeplink-modal__icon {
      color: var(--color-success);
    }
  }

  &--error {
    .deeplink-modal__status .deeplink-modal__icon {
      color: var(--color-danger);
    }
  }

  @media (min-width: $screen-sm + 1) {
    &__status {
      text-align: center;
    }

    &__text {
      width: 80%;
      text-align: center;
    }

    &--error &__text {
      margin-top: 44px;
    }
  }
}
