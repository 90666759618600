@import "../../../../styles/mixins/rtl";
@import "../../../../styles/colors";

.user-menu {
  @include side-spacing(margin, left, 32px);
  color: var(--color-primary);

  .dropdown__menu {
    min-width: 160px;
    margin-top: 16px;
    padding: 10px 0;
    @include side-position(right, 0);

    .warning:not(.dropdown__item--highlighted) {
      color: var(--color-danger);
    }
  }
}
