@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";
@import "../../styles/variables";

.devices {
  flex: 1 0 100%;
  height: 100%;

  &__spson-panel {
    margin-bottom: 14px;
  }

  .section-header {
    display: flex;
    align-items: center;

    &__btn {
      @include side-spacing(margin, left, auto);
    }

    &__title {
      @include ellipsis;
    }

    @media (max-width: $screen-sm) {
      flex-direction: column;
      align-items: unset;

      &__text {
        margin-bottom: 15px;
      }

      &__btn {
        margin: 0;
        width: 100%;
      }
    }
  }

  .identifiers-block {
    display: inline-block;
    vertical-align: middle;
  }

  &--discovered {
    @media (max-width: $screen-xs-max) {
      .manufacturer {
        @include side-spacing(margin, left, 24px);
      }
    }
  }

  .sidebar__content {
    //adjust spacings to prevent the content jumping
    //height of SPSON panel (50px) plus margin (14px)
    & > h4:first-child {
      margin-top: 64px;
    }
  }
}
