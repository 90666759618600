@import "../../styles/colors";
@import "../../styles/variables";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-loader;

  background-color: rgba($white, 0.5);

  &__dot {
    height: 12px;
    width: 12px;
    border-radius: 10px;
    background-color: $geyser;
    margin-right: 8px;

    animation-name: loader__dot;
    animation-duration: 0.9s;
    animation-timing-function: cubic-bezier(1, 0, 1, 1);
    animation-iteration-count: infinite;

    &--1 {
      animation-delay: 0s;
    }

    &--2 {
      animation-delay: 0.3s;
    }

    &--3 {
      animation-delay: 0.6s;
    }
  }
}

@keyframes loader__dot {
  0% {
    background-color: var(--color-primary);
  }

  33% {
    background-color: $geyser;
  }
}
