@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";

.blocked-page {
  padding: 0;

  &__title {
    margin-bottom: 16px;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 840px;
  }

  .form-section {
    margin-bottom: 32px;

    .checkbox-wrapper:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: $screen-xs-max) {
    .form-section:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $geyser;
    }

    .admin-info {
      margin-bottom: 0;
    }

    .textarea {
      margin-top: 24px;
    }
  }

  .web-filtering-form__device-registration__controls__label {
    @extend .text-label;
    margin-bottom: 16px;
  }
}
