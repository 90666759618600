@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/colors";
@import "../../../../../styles/mixins/rtl";

.rule-popup {
  position: absolute;
  z-index: $zindex-dropdown;

  width: 485px;
  padding: 24px;
  padding-top: 16px;
  margin-top: 8px;
  background-color: $white;

  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid $geyser;
  border-radius: 3px;

  &.create {
    &::before,
    &::after {
      position: absolute;
      content: "";
      height: 0;
      width: 0;
      bottom: 100%;
      border: solid transparent;
    }

    &::before {
      border-bottom-color: $geyser;
      border-width: 7px;
    }

    &::after {
      border-bottom-color: $white;
      border-width: 6px;
      @include side-position(left, 25px);
    }
  }

  &.edit {
    @include side-spacing(margin, right, 8px);
    top: 0;

    &::before,
    &::after {
      position: absolute;
      content: "";
      height: 0;
      width: 0;
      @include side-position(right, -15px);
      border: 8px solid transparent;
    }

    &::before {
      @include oneside(border, left, 7px solid $geyser);
    }

    &::after {
      @include oneside(border, left, 7px solid $white);
      @include side-position(right, -13px);
      top: 16px;
    }

    @media (min-width: $screen-lg-min) {
      @include side-position(right, 100%);
    }
  }

  &__time,
  &__preview {
    margin-bottom: 16px;
  }

  &__header {
    @extend h4;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 16px;

    &__icon {
      width: 16px;
      color: $boulder;
      cursor: pointer;
    }
  }

  &__time {
    display: flex;

    &__picker {
      label {
        display: block;
        margin-bottom: 8px;
        color: $mid-gray;
      }

      .time-picker-wrapper {
        &:first-of-type {
          @include side-spacing(margin, right, 32px);
        }
      }
    }
  }

  &__actions {
    display: flex;

    &--right {
      @include side-spacing(margin, left, auto);
    }
  }
}
