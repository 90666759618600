@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/mixins/typography";

.internet-security {
  @media (max-width: $screen-xs-max) {
    border-top: 1px solid $geyser;
    padding: 24px 16px 0;
    margin: 0 -16px 16px;

    .web-filters__header {
      margin-bottom: 16px;
    }
  }

  &__option {
    @include side-spacing(margin, right, 40px);
    display: inline-block;

    @media (max-width: $screen-xs-max) {
      display: block;
      margin-bottom: 24px;
    }
  }

  .security-description {
    line-height: 20px;
    margin-bottom: 24px;

    @media (max-width: $screen-xs-max) {
      @include text-caption;
    }
  }

  .subsection-header__description {
    margin-bottom: 16px;

    @media (max-width: $screen-xs-max) {
      @include text-caption;
    }
  }

  .subsection-header__title,
  .subscriber-safety {
    margin-bottom: 8px;
  }

  .subscriber-safety {
    @media (max-width: $screen-xs-max) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .subsection-header {
    @media (max-width: $screen-xs-max) {
      border-top: 1px solid $geyser;
      padding: 24px 0 0;
    }

    &__title {
      margin-bottom: 8px;
    }
  }
}
