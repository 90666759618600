@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/mixins/typography";

.blocked-page {
  .tab-controls {
    margin-bottom: 24px;
    display: flex;

    &__saving-buttons {
      @include side-spacing(margin, left, auto);
    }

    @media (max-width: $screen-xs-max) {
      display: block;
      margin-bottom: 40px;

      .preview-button {
        width: 100%;
        margin-bottom: 16px;
      }

      &__saving-buttons {
        display: flex;

        button {
          flex: 1;
        }
      }

      .web-filtering-form__send-button {
        @include ellipsis;
        @include side-spacing(margin, left, auto);
      }
    }
  }
}
