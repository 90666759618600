@import "../../../../../styles/variables";
@import "../../../../../styles/typography";

.schedule-time {
  display: flex;

  label:not(.select__label) {
    @extend .text-label;
  }

  @media (max-width: $screen-xs-max) {
    &__picker:first-child {
      @include side-spacing(margin, right, 24px);
    }

    margin-bottom: 16px;
  }

  @media (min-width: $screen-sm-min) {
    flex-wrap: wrap;

    &__picker {
      margin-bottom: 16px;

      &:first-child {
        @include side-spacing(margin, right, 32px);
      }
    }
  }
}
