@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";

.textarea {
  &:focus {
    outline: none;
  }

  &__info {
    @include text-caption;
    display: flex;
    margin-top: 8px;
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 64px;
    padding: 10px 16px;
    color: $mid-gray;
    background-color: $white;
    border: 1px solid $geyser;
    border-radius: 3px;
    max-width: 100%;

    @media (max-width: $screen-xs-max) {
      min-height: 84px;
      padding: 8px 16px;
    }

    &:hover {
      border-color: $gray-chateau;
    }

    &:focus {
      border-color: var(--color-primary);
      outline: none;
    }

    &::placeholder {
      @include text-hint;
      opacity: 1;
    }
  }
}

.has-error {
  textarea {
    border-color: var(--color-danger);
    &:focus,
    &:hover {
      border-color: var(--color-danger);
    }
  }
}
