@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/colors";

.help-content {
  color: $mine-shaft;
  font-weight: 400;

  mark {
    background-color: var(--color-text-highlight);
  }

  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.5;
  }

  h1 {
    font-size: 24px;
    padding-top: 1.5em;
    padding-bottom: 1em;
  }

  h2 {
    font-size: 18px;
    padding-top: 1.25em;
  }

  h3 {
    font-size: 14px;
    padding-top: 0.75em;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 5px;
  }

  p,
  ul,
  ol {
    line-height: 1.6;
    padding-bottom: 1em;
  }

  li {
    line-height: 1.6;
    padding-bottom: 5px;
  }

  // Reset Reset
  ul,
  ol {
    list-style: initial;
    @include oneside("padding", "left", 40px); // Mimic chrome's styles
  }

  strong {
    font-weight: 600;
  }

  // Mimic Bootstrap 3
  blockquote {
    padding: 9px 18px;
    margin: 0 0 18px;
    font-size: 16px;
    @include oneside(border, left, 5px solid $porcelain);
  }

  // Mimic Bootstrap 3
  code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 3px;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  hr {
    border: 0;
    line-height: 22px;
    border-top: 1px solid $porcelain;
  }

  // Styles for `figure` allows customers to place images floated by text
  // as it is documented in ui-customization.md
  figure,
  img {
    padding-bottom: 30px;
    max-width: 100%;
  }

  figure > img {
    margin: 0;
  }

  figure.center {
    text-align: center;
  }

  figure.left {
    max-width: 465px;
    @include float(left);
    @include side-spacing(margin, right, 16px);
  }

  figure.right {
    max-width: 465px;
    @include float(right);
    @include side-spacing(margin, left, 16px);
  }

  br,
  hr,
  h1,
  h2,
  h3,
  figure,
  img {
    clear: both;
  }
}
