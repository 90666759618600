@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";

.copyable {
  .tooltip-referrer {
    display: block;
  }

  &-tooltip__icon {
    @include side-spacing(margin, right, 8px);
    @include font-size(regular);
    color: $cerulean;
  }
}

.tooltip--copyable {
  .tooltip__content {
    padding-left: 8px;
    padding-right: 8px;
  }
}
