@import "../../../styles/colors";

.protection-status {
  .icon {
    font-size: 16px;
  }

  &--disabled {
    color: $mid-gray;
  }

  &--enabled {
    color: var(--color-success);
  }

  &--unknown {
    color: $gray-chateau;
  }
}
