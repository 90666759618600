@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.login-page {
  justify-content: center;

  .login__header {
    margin-bottom: 16px;

    @media (max-width: $screen-xs-max) {
      padding: 13px 0;
      text-align: center;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    margin-top: 150px;

    .login__header {
      @include text-align(left);
    }

    &__button {
      width: auto;
      @include side-spacing(margin, left, auto);
      margin-top: 16px;
    }

    @media (max-width: $screen-xs-max) {
      max-width: 350px;
      margin-top: 30px;

      &__button {
        width: 100%;
        height: auto;
        padding: 13px 15px;
      }
    }
  }

  .login-text-block {
    max-width: 350px;
    margin: 40px auto 0;
    text-align: center;
    flex: auto;

    @media (min-width: $screen-sm + 1) {
      margin-top: 150px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}
