@import "../../styles/colors";
@import "../../styles/buttons";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";

.widget {
  text-align: center;
  max-width: calc(1 / 3 * 100%);
  width: 100%;

  @media (min-width: $screen-sm-min) {
    + .widget {
      @include side-spacing(margin, left, 24px);
    }
  }

  &__count {
    margin-bottom: 14px;

    .icon {
      font-size: 24px;
      color: $gray-chateau;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;

      @media (max-width: $screen-sm-max) {
        @include side-spacing(margin, left, 4px);
      }

      @media (min-width: $screen-md-min) {
        @include side-spacing(margin, left, 8px);
      }

      font-size: 38px;
      @include font-weight("semi-bold");
    }
  }

  &__title {
    margin-bottom: 16px;

    .icon {
      color: $gray-chateau;
      cursor: pointer;
    }

    span + span {
      @include side-spacing(margin, left, 5px);
    }
  }

  .tooltip-referrer {
    @include side-spacing(margin, left, 8px);
  }

  &__button {
    margin-top: 8px;
  }

  &__link {
    text-align: center;
  }

  @media (max-width: $screen-xs-max) {
    width: 100%;
    max-width: none;
    position: relative;
    padding: 0;
    border: 1px solid $geyser;
    border-radius: 3px;
    height: 80px;

    + .widget {
      margin-top: 8px;
    }

    &__header {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      @include side-spacing(margin, left, 16px);

      @media (max-width: $screen-xs-max) {
        @include font-size(small);
      }

      .icon {
        color: var(--color-primary);
      }
    }

    &__title {
      text-transform: none;
      height: auto;
      letter-spacing: initial;
      margin-bottom: 4px;
    }

    &__count {
      @include text-align(left);
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }

    &__overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &__link {
      z-index: 3;
      position: absolute;
      right: -1px;
    }

    &__button {
      top: -1px;
      margin: 0;
      padding: 0;
      height: 80px;
      width: 24px;

      display: flex;
      justify-content: center;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.recharts-tooltip-wrapper {
  z-index: $zindex-tooltip;
}

.tooltip-reports {
  @include font-size(small);
  background-color: rgba($white, 0.8);
  padding: 10px 15px;
  color: $mine-shaft;
  min-width: 155px;

  &_time {
    margin-bottom: 10px;
  }

  &_list {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &_header {
      margin-bottom: 5px;
      font-weight: 600;
    }
  }
}

.homepage-reports {
  .select-native {
    margin-bottom: 20px;
  }
}

.barchart-container {
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .x-axis {
    color: $geyser;
    @include font-size(regular);
    font-family: "Open Sans", Arial, sans-serif;

    text {
      color: var(--color-secondary);
    }
    .domain {
      fill: #f8f8f8;
    }
  }

  .no-data-message {
    fill: $boulder;
  }

  .tooltip-reports {
    position: absolute;
    top: 0;
  }
}

//COLOR SCHEMES
.barchart {
  .activity-group {
    fill: var(--color-report-activity);
  }

  .webfilter-group {
    fill: var(--color-report-webfilter);

    .rect-stack--brushed {
      fill: var(--color-report-webfilter-brushed);
    }
  }

  .malware-group {
    fill: var(--color-report-malware);

    .rect-stack--brushed {
      fill: var(--color-report-malware-brushed);
    }
  }

  .botnet-group {
    fill: var(--color-report-botnet);

    .rect-stack--brushed {
      fill: var(--color-report-botnet-brushed);
    }
  }

  .rect-stack--hover {
    opacity: 0.5;
  }
}
