@import "../../styles/colors";
@import "../../styles/variables";

.progress-button {
  position: relative;
  transition: none; // a quick fix for iPad2
  min-width: 100px;

  & &__label {
    &--hidden {
      opacity: 0;
      position: static;
    }

    &--icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & &__loader {
    position: absolute;
    left: 50%;
    margin-left: -16px;
    width: 33px;
    height: 36px;
    top: 0;
  }

  .progress-button-loader__block {
    bottom: 5px;
    opacity: 0;
    display: block;
    position: absolute;
    width: 3px;
    height: 7px;
    background: $white;
    animation-name: flying-blocks;
    animation-duration: 0.42s;
    animation-timing-function: linear;
    animation-iteration-count: 10000;

    &:nth-child(1) {
      left: 0;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      left: 6px;
      animation-delay: 0.13s;
    }

    &:nth-child(3) {
      left: 12px;
      animation-delay: 0s;
    }

    &:nth-child(4) {
      left: 18px;
      animation-delay: 0.36s;
    }

    &:nth-child(5) {
      left: 24px;
      animation-delay: 0.23s;
    }

    &:nth-child(6) {
      left: 30px;
      animation-delay: 0.35s;
    }
  }

  @keyframes flying-blocks {
    0% {
      bottom: 5px;
      opacity: 0;
    }

    40% {
      bottom: 11px;
      opacity: 1;
    }

    60% {
      bottom: 18px;
      opacity: 1;
    }

    100% {
      bottom: 24px;
      opacity: 0;
    }
  }
}
