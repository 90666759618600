@import "../../../styles/colors";
@import "../../../styles/mixins/typography";
@import "../../../styles/mixins/rtl";

.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  &__icon {
    flex: 0 0 auto;
    @include side-spacing(margin, right, 5px);
  }

  &__content {
    flex: 1 1 auto;
    min-width: 0;
    @include side-spacing(margin, right, 10px);
    @include ellipsis;
  }

  &__remove-link {
    @include side-spacing(margin, left, auto);
    @include side-spacing(margin, right, 4px);
    @include font-size(regular);
  }

  &__remove-icon {
    color: $mid-gray;
  }
}
