@import "../../../../../styles/colors";
@import "../../../../../styles/mixins/typography";

.report-tooltip {
  padding: 12px 16px;
  font-size: 11px;
  line-height: 17px;
  background-color: $white;
  color: rgba($black, 0.7);
  pointer-events: none;

  &__counts,
  &__top .list:first-child {
    margin-bottom: 12px;
  }

  &__counts,
  &__top {
    margin-top: 8px;
    white-space: nowrap;
  }

  &__top {
    .list__header {
      font-weight: 600;
      @include font-size("small");
    }
  }
}
