@import "../../../styles/colors";
@import "../../../styles/variables";

.help-header {
  position: fixed;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: $zindex-mobile-menu;

  + .layout-container {
    padding-top: 80px;
  }

  &__navigation {
    padding-top: 42px;
  }
}
