@import "../../../../../styles/colors";
@import "../../../../../styles/mixins/rtl";

.protection-editor-modal {
  .categories-actions {
    flex-direction: column;
    padding: 0;

    &--title {
      display: flex;
    }

    .filter-actions {
      @include side-spacing(margin, left, 8px);
      color: $mid-gray;
    }

    .list-actions {
      display: flex;
      padding: 8px 16px;
      margin: 8px -16px;
      background-color: $porcelain;
      border-top: 1px solid $geyser;
      border-bottom: 1px solid $geyser;

      .btn {
        flex: 1;
      }
    }
  }

  .categories-list-wrapper {
    overflow: auto;
  }
}

.protection-editor {
  &__settings-btn {
    width: 100%;
  }
}
