@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/mixins";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";

.checkbox {
  &__check,
  &__check:before {
    transition: 0.3s;
  }

  &__check:before {
    @include fa-icon();
    display: block;
    width: 16px;
    height: 16px;
    content: fa-content($fa-var-check);
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;

    visibility: visible;
    font-size: 12px;

    border-radius: 2px;
    border: 2px solid $mid-gray;
    color: $white;
    background-color: $white;
  }

  &__input {
    display: none !important;

    &:checked ~ .checkbox__check:before {
      background-color: var(--color-primary);
      border: 2px solid var(--color-primary);
    }

    &:disabled {
      & ~ .checkbox__check:before {
        color: $geyser;
      }

      & ~ .checkbox__check,
      & ~ .checkbox__check:before,
      & ~ .checkbox__text {
        cursor: not-allowed;
      }

      &:not(:checked) {
        & ~ .checkbox__check:before {
          background-color: $porcelain;
          color: $porcelain;
          border: 2px solid $geyser;
        }
      }

      &:checked {
        & ~ .checkbox__check:before {
          background-color: $mid-gray;
          border: 1px solid $mid-gray;
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__label {
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      cursor: pointer;

      .checkbox__input:not(:checked):enabled {
        & ~ .checkbox__check:before {
          border-color: var(--color-primary);
        }
      }

      .checkbox__input:checked {
        &:enabled ~ .checkbox__check:before {
          background-color: var(--color-primary-hover);
          border-color: var(--color-primary-hover);
        }

        &:disabled ~ .checkbox__check:before {
          background-color: $mid-gray;
          border-color: $mid-gray;
        }
      }
    }
  }

  &__text {
    display: inline-block;
    @include side-spacing(padding, left, 8px);
    @include side-position(left, 10px);
  }

  &__description {
    @include side-spacing(margin, left, 24px);
    @include text-caption;
    margin-top: 8px;
  }

  @media (max-width: $screen-xs-max) {
    &__check {
      border: 2px solid $mid-gray;
      border-radius: 2px;
      padding: 2px;

      &:before {
        border-radius: 0;
        border-color: $white;
      }
    }

    &__input {
      &:checked ~ .checkbox__check {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
      }

      &:disabled {
        & ~ .checkbox__check {
          color: $geyser;
        }

        &:not(:checked) {
          & ~ .checkbox__check {
            background-color: $porcelain;
            border-color: $geyser;

            &:before {
              border-radius: 0;
              border-color: $porcelain;
            }
          }
        }

        &:checked {
          & ~ .checkbox__check {
            background-color: $mid-gray;
            border-color: $mid-gray;
          }
        }
      }
    }

    &__label:hover {
      cursor: pointer;

      .checkbox__input:not(:checked):enabled {
        & ~ .checkbox__check {
          border-color: var(--color-primary);

          &:before {
            border-color: $white;
          }
        }
      }

      .checkbox__input:checked {
        &:enabled ~ .checkbox__check {
          background-color: var(--color-primary-hover);
          border-color: var(--color-primary-hover);
        }

        &:disabled ~ .checkbox__check {
          background-color: $mid-gray;
          border-color: $mid-gray;
        }
      }
    }

    &__description {
      @include side-spacing(margin, left, 0);
    }
  }
}
