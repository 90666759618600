@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";

.radio {
  padding: 0;
  position: relative;
  border: 1px solid $white-ice;

  &--selected {
    border: 1px solid var(--color-primary);
  }

  @media (max-width: $screen-xs-max) {
    border: 1px solid var(--color-primary);
  }

  &:hover {
    cursor: pointer;

    .radio__check:before,
    .radio__input:checked ~ .radio__check:before {
      border-color: var(--color-primary-hover);
    }

    .radio__input:not(:checked) ~ .radio__check:before {
      border-color: var(--color-primary);
    }

    .radio__check:after {
      background: var(--color-primary-hover);
    }

    .radio__input:disabled:checked ~ .radio__check {
      cursor: not-allowed;

      &:before {
        border-color: $mid-gray;
      }
    }
    .radio__input:disabled:not(:checked) ~ .radio__check {
      cursor: not-allowed;

      &:before {
        border-color: $geyser;
      }
    }
  }

  &__input {
    display: none;

    &:checked + .radio__check:before {
      opacity: 1;
      border-color: var(--color-primary);

      &:hover {
        border-color: var(--color-primary-hover);
      }
    }

    &:checked + .radio__check:after {
      opacity: 1;
    }

    &:disabled {
      & ~ .radio__check:before,
      & ~ .radio__text {
        cursor: not-allowed;
        border-color: $geyser;
      }

      & ~ .radio__check:before {
        background-color: $porcelain;
      }

      &:checked ~ .radio__check {
        &:before {
          border-color: $mid-gray;
        }
        &:after {
          background-color: $mid-gray;
        }
      }
    }
  }

  &__check {
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      @include side-position(left, 16px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      transform: translateY(-50%);

      @media (max-width: $screen-xs-max) {
        top: 12px;
        @include side-position(left, 8px);
        transform: translateY(0);
      }
    }

    &:before {
      content: "";
      border: 1px solid var(--color-primary);
      background: $white;
    }

    &:after {
      @include font-size(small);
      font-family: "Font Awesome 5 Pro";
      content: fa-content($fa-var-check);
      font-weight: 300;
      padding-top: 1px;
      text-align: center;
      color: $white;
      background: var(--color-primary);
      opacity: 0;
    }
  }

  &__text {
    display: inline-block;
    width: 100%;
  }
}
