@import "../../../../styles/variables";

.check-url-block {
  flex: 0 0 auto;
  margin-bottom: 24px;
}

.url-filters {
  height: 100%;

  .sidebar__content {
    //adjust spacings to prevent the content jumping
    h4:first-child {
      margin-top: 64px;
    }

    h4 {
      margin-bottom: 8px;
    }
  }

  &__subheader {
    margin-bottom: 8px;
  }
}
