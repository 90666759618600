@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.combobox {
  .select {
    &__search {
      border: none;
      padding: 4px 8px 8px;
    }

    // todo: temporary solution
    &__search-input {
      width: 100%;
      height: 32px;
      @include side-spacing(padding, right, 16px);
      @include side-spacing(padding, left, 8px);
      background-color: $white;
      border: 1px solid $geyser;
      border-radius: 3px;

      &:hover {
        border-color: $gray-chateau;
      }

      &:focus,
      &:active {
        border-color: var(--color-primary);
        outline: 0;
      }

      &[disabled] {
        cursor: not-allowed;
        background-color: $porcelain;
        opacity: 1;
      }

      &::placeholder {
        font-style: italic;
        color: $gray-chateau;
      }

      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }
    }
    // end temp solution

    &__menu-wrapper {
      outline: auto var(--color-primary);
      border-radius: 3px;
      border: 1px solid var(--color-controls-border);
      box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.16);
      padding: 4px 0;
    }

    &-menu {
      margin: 0 2px;
      border: none;
      box-shadow: none;
      padding: 0;

      &__no-results {
        padding: 5px 16px;
      }
    }
  }
}
