@import "../../../styles/mixins/direction";
@import "../../../styles/mixins/ellipsis";

.select__button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &-content {
    @include ellipsis;
    white-space: pre;
  }

  &-arrow {
    display: flex;
    align-items: center;
    height: 24px;
    @include side-spacing(margin, left, 16px);
  }
}
