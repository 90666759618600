@mixin side-spacing($property, $side, $value) {
  @include oneside($property, $side, $value);
}

@mixin oneside($property, $side, $value) {
  .ltr & {
    #{$property}-#{$side}: $value;
  }

  .rtl & {
    @include reverse($property, $side, $value);
  }
}

@mixin reverse($property, $side, $value) {
  @if ($side == right) {
    #{$property}-left: $value;
  } @else if ($side == left) {
    #{$property}-right: $value;
  }
}

@mixin side-position($side, $value) {
  .ltr & {
    #{$side}: $value;
  }

  .rtl & {
    @include reverse-property($side, $value);
  }
}

@mixin reverse-property($side, $value) {
  @if ($side == right) {
    left: $value;
  } @else if ($side == left) {
    right: $value;
  }
}

@mixin float($side) {
  @include oneside-value(float, $side);
}

@mixin text-align($side) {
  @include oneside-value(text-align, $side);
}

@mixin oneside-value($property, $side) {
  .ltr & {
    #{$property}: $side;
  }

  .rtl & {
    @include reverse-value($property, $side);
  }
}

@mixin reverse-value($property, $side) {
  @if ($side == right) {
    #{$property}: left;
  } @else if ($side == left) {
    #{$property}: right;
  }
}

@mixin border-position-radius($position, $side, $value) {
  .ltr & {
    border-#{$position}-#{$side}-radius: $value;
  }

  .rtl & {
    @if ($side == right) {
      border-#{$position}-left-radius: $value;
    } @else if ($side == left) {
      border-#{$position}-right-radius: $value;
    }
  }
}
