@import "../../../../styles/colors";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/mixins/typography";

.profile-tab {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 3px;

  &,
  &:hover {
    color: $mine-shaft;
  }

  &__content {
    flex: 0 1 100%;
    max-width: calc(100% - 37px);
  }

  &__title {
    @include font-weight(semi-bold);
    word-wrap: break-word;
    margin-bottom: 8px;
  }

  &__dropdown {
    @include side-spacing(margin, left, auto);
  }

  .profile-tab__toggle-actions {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    &[aria-expanded="true"] {
      background-color: $geyser;
    }
  }

  .dropdown {
    &__menu {
      @include side-position(right, 0);
    }

    &__item {
      &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

// this selector used externally and can be applied on parent of `.profile-tab`
.profile-tab--active {
  .profile-tab__content,
  .profile-tab__dropdown {
    color: $white;
  }

  .profile-tab__description {
    @include font-weight(semi-bold);
  }

  .profile-tab__toggle-actions[aria-expanded="true"] {
    background-color: var(--color-primary-hover);
  }
}
