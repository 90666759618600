@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";

.modal__dialog {
  div[class$="__select-stub__text"] {
    height: 40px;
    padding: 10px 16px;
    background-color: $porcelain;
    @include ellipsis;
  }

  div[class$="__identifiers"] {
    margin-bottom: 16px;

    &:not(:first-child) {
      margin-bottom: 8px;
      height: 32px;
      padding: 5px 16px;
      display: inline-block;
      border-radius: 3px;

      span[class$="-title"] {
        @include font-weight("semi-bold");
      }

      .icon {
        @include side-spacing(margin, left, 8px);
      }
    }
  }

  div[class*="__identifiers--disabled"] {
    background-color: $black-haze;
    color: rgba($mine-shaft, 0.5);
  }

  span[class$="__highlighted-text"],
  div[class$="__select-stub__text"] {
    @include text-highlighted;
  }

  .device-name {
    @include text-highlighted;
    word-break: break-word;
    white-space: pre-line;
  }

  .selected-custom {
    &__placeholder {
      @include text-hint;
    }

    &__label {
      @include text-caption;
      @include side-spacing(margin, right, 16px);
    }

    &__id {
      @include text-highlighted;
      @include side-spacing(margin, right, 8px);
      color: var(--color-primary);
    }

    &__info {
      @include text-caption;
    }
  }

  .button-link[disabled] {
    cursor: not-allowed;

    .icon {
      opacity: 0.6;
    }
  }
}
