@import "../../../../styles/colors";
@import "../../../../styles/buttons";
@import "../../../../styles/typography";
@import "../../../../styles/mixins/rtl";

@mixin box() {
  height: 80px;
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload {
  &__box {
    @include box();
    background-color: $white;
    border: 1px solid $geyser;
    border-radius: 3px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__hints {
      display: flex;
      margin-bottom: 16px;
      @include text-caption;

      &__labels {
        @include side-spacing(margin, right, 16px);
        color: var(--color-secondary);
        > div:first-child {
          margin-bottom: 8px;
        }
      }

      &__values {
        @include text-highlighted;
        > div:first-child {
          margin-bottom: 8px;
        }
      }
    }

    &__controls {
      &__change {
        @include create-button(6px, 10px, 3px);
        @include create-outline-button-variant(
          var(--color-main),
          $white,
          $gray-chateau,
          $geyser,
          darken($geyser, 10%)
        );
        @include side-spacing(margin, right, 8px);
        height: 32px;
      }

      @media (max-width: $screen-xs-max) {
        display: flex;

        > * {
          width: 100%;
          height: 40px;
          padding: 10px;
        }
      }
    }
  }

  &__error {
    color: var(--color-danger);
    margin-top: 8px;
  }

  &__upload-button {
    padding: 8px 0;

    &__label {
      padding: 8px 14px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.upload-field {
  @include box();

  border: 1px dashed $mine-shaft;
  background-color: $black-haze;
  color: var(--color-primary);

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border-color: var(--color-primary-hover);
    background-color: $white;
  }

  &__icon {
    font-size: 20px;
  }
}
