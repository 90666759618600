@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/mixins/rtl";

.profile-picker {
  &__header {
    margin: -24px -16px 16px;
    background-color: $black-haze;
    padding: 24px 16px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }

    &__label {
      width: 100%;

      @media (min-width: $screen-sm-min) {
        max-width: 389px;
      }
    }

    &__controls {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-sm-min) {
        align-items: flex-end;
        flex-direction: row;
        width: 750px;
        @include side-spacing(margin, left, calc((100vw - 782px) / 2));
      }

      &__side-section {
        display: flex;

        .btn {
          border-color: $geyser;

          &:not(:last-of-type) {
            @include side-spacing(margin, right, 8px);
          }

          @media (max-width: $screen-xs-max) {
            margin-top: 8px;
          }

          &:last-child {
            flex: 1 1 auto;
          }
        }

        @media (min-width: $screen-sm-min) {
          @include side-spacing(padding, left, 8px);
        }

        .tab-description-message {
          line-height: 40px;
        }

        .icon {
          color: $mid-gray;
        }
      }
    }

    .counter {
      margin-bottom: 8px;
    }

    .counter__label {
      @include side-spacing(margin, right, 4px);
      @extend h4;
    }
  }
}
