@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.show-toggle {
  display: inline-block;
  font-size: 14px;
  height: 24px;
  width: 24px;
  border: 1px solid $geyser;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  color: $boulder;
  @include side-spacing(margin, right, 8px);
}
