@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";

.separated-lists {
  display: flex;
  flex-direction: row;

  &__item {
    $list-item-padding: 15px;
    $border: 1px solid $porcelain;
    $transparent-border: 1px solid transparent;

    flex: 1 1 0%;
    min-width: 0;
    padding: 0 $list-item-padding;

    @media (min-width: $screen-sm-min) {
      &:first-child {
        @include side-spacing(margin, left, -$list-item-padding);
        border-right: $border;
      }

      &:last-child {
        @include side-spacing(margin, right, -$list-item-padding);
        @include oneside(border, left, $transparent-border);
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    flex-direction: column;

    &__item:first-child,
    &__item:last-child {
      flex: 1 1 100%;
      margin: 0 0 24px;
      padding: 0;
      border: none;
    }
  }
}
