@import "../../../../../styles/colors";

.brush-container {
  .selection {
    fill: var(--color-report-brush-selection);
    fill-opacity: 0.2;
  }

  .handle {
    fill: var(--color-report-brush-selection-border);
  }
}
