@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/mixins";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";

.drilldowns {
  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 8px;

    .icon {
      font-size: 24px;
      @include side-spacing(margin, right, 16px);
    }
  }

  &__chart {
    margin-bottom: 40px;

    @media (max-width: $screen-xs-max) {
      margin-top: 16px;
    }
  }

  &__table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__original-url .tooltip-referrer {
      display: block;
      @include ellipsis;
    }

    &__time-cell {
      width: 250px;
    }

    &__info {
      @include side-spacing(margin, right, 12px);
    }

    &__warning {
      margin: 0 16px;

      & > :first-child {
        color: $mine-shaft;
      }
    }

    &__footer-content {
      text-align: center;
      margin: 32px 0;

      button {
        margin-top: 16px;
      }
    }

    .table__search {
      width: 100%;
      flex-grow: 1;

      @media (min-width: $screen-sm-min) {
        width: auto;
        max-width: 216px;
      }
    }

    @media (max-width: $screen-sm) {
      .table {
        width: 100%;
      }

      .cell--url .table__cell__content {
        @include side-spacing(margin, left, 24px);
        @include ellipsis;
      }
    }

    .table-card {
      .count {
        height: 24px;
        min-width: 25px;
        padding: 0 8px;
        border-radius: 3px;
        background-color: $geyser;
        text-align: center;
      }

      .placeholder {
        color: $boulder;
      }
    }
  }

  .time-range {
    display: flex;
    align-items: center;

    background-color: $black-haze;
    padding: 4px 16px;
    margin: 24px 0;

    &__title,
    &__info {
      @include side-spacing(margin, right, 8px);
    }

    &__info {
      @include text-highlighted;
    }
  }

  @media (max-width: $screen-sm-max) {
    &__chart {
      margin-bottom: 0;
    }

    .time-range {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      &__info {
        margin: 0;
      }

      &__reset {
        position: absolute;
        @include side-position(right, 0);
        padding: 12px;
      }
    }

    &__header {
      a {
        display: inline-block;
        margin-bottom: 16px;
      }
    }

    .rtl & {
      &__header {
        i:before {
          content: fa-content($fa-var-angle-right);
        }
      }
    }
  }

  .recharts-label {
    @include font-size(regular);
    fill: $boulder;
  }
}
