@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.device-menu {
  display: inline-block;

  &__toggle-button {
    width: 24px;
    height: 24px;

    &[aria-expanded="true"] {
      background-color: $geyser;
    }
  }

  .dropdown__menu {
    @include side-position(right, 0);
  }

  .dropdown__item {
    .icon {
      padding: 0;
    }

    &:not(.dropdown__item--highlighted) {
      .icon {
        color: $boulder;
      }
    }
  }
}
