.preview-page {
  width: 100%;
  height: 100%;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100vh;
    border: 0;
  }
}
