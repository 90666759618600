.url-list-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    flex: 0 0 auto;
    font-size: 20px * 1.4;
  }

  &__description {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }
}
