@import "../../../../styles/colors";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/typography";
@import "../../../../styles/variables";

.schedule {
  margin-bottom: 40px;

  @media (max-width: $screen-xs-max) {
    &:not(.internet-off) {
      border-top: 1px solid $geyser;
      padding: 24px 0 0;
      margin-bottom: 24px;
    }
  }

  .checkbox__text {
    color: $mine-shaft;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 24px;

    &--disabled:after {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: "";
      background-color: $white;
      opacity: 0.5;
      z-index: 100;
    }
  }

  &__description,
  &__error {
    width: 100%;
    margin-top: 8px;
  }

  &__error {
    @extend .color-danger;
  }

  &__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding-bottom: 40px;

    &__timetable {
      display: flex;
      flex: 1 1 auto;

      @media (max-width: $screen-md-max) {
        flex-wrap: wrap-reverse;
      }
    }

    &--disabled {
      &:after {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        background-color: $white;
        opacity: 0.6;
        z-index: $zindex-spinner-local;
      }
    }

    @media (min-width: $screen-sm-min) {
      &:before {
        @include side-position(left, 48px);
      }
    }
  }

  &__categories {
    width: 100%;

    .protection-editor__list-container {
      height: 272px;
    }
  }

  &__categories-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .search-section {
      min-width: 300px;
      margin-bottom: 16px;

      @include side-spacing(margin, left, 16px);
    }
  }

  &__categories-description {
    margin-bottom: 20px;
  }

  &__settings-btn {
    width: 100%;
    margin-bottom: 8px;

    &__counter {
      font-weight: 600;
    }
  }

  .create-rule {
    position: relative;
    margin: 0 0 8px;
  }

  @media (max-width: $screen-xs-max) {
    &__header {
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &__description {
      margin-bottom: 8px;
      @include text-caption;
    }

    .create-rule {
      .btn {
        width: 100%;
      }
    }
  }
}

.internet-off__header {
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
    line-height: 20px;
    border-top: 1px solid $geyser;
    padding: 24px 16px 0;
    margin: 0 -16px 16px;
  }
}
