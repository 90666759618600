@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";

.remove-device-modal {
  &__message {
    margin-bottom: 20px;
  }

  &__note {
    color: var(--color-secondary);
  }
}
