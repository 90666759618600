@import "colors";
@import "variables";
@import "mixins/rtl";
@import "mixins/typography";

h1 {
  @include font-size("xx-large");
}

h2 {
  @include font-size("x-large");
  @include font-weight("semi-bold");
}

h3 {
  @include font-size("large");
  @include font-weight("regular");
}

h4 {
  @include font-size("medium");
  @include font-weight("semi-bold");
}

.text-highlighted {
  @include text-highlighted;
}

.text-hint {
  @include text-hint;
}

.text-caption {
  @include text-caption;
}

.text-label {
  @include text-label;
  display: inline-block;
  margin-bottom: 8px;
}

.color-primary {
  color: var(--color-primary);
}

.color-danger {
  color: var(--color-danger);
}

.color-success {
  color: var(--color-success);
}

.color-secondary {
  color: var(--color-secondary);
}

a,
.link {
  @include font-weight("regular");

  text-decoration: none;
  cursor: pointer;
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary-hover);
  }

  &[disabled],
  &.disabled {
    color: var(--color-primary-disabled);
    pointer-events: none;
  }

  & + a {
    @include side-spacing(margin, left, 15px);
  }
}

.link--small {
  @include font-size("small");
  @include font-weight("semi-bold");
}
