@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/layout";
@import "../../../styles/mixins/typography";

.header {
  background-color: $white;

  &__content {
    display: flex;
    align-items: center;
    padding: 12px 0;
    justify-content: space-between;
    @include adaptive(750px, 970px, 1170px);
    margin: 0 auto;

    @media (min-width: $screen-sm-min) {
      height: 80px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 8px 16px;
    }
  }

  &--bordered {
    border-bottom: 1px solid $porcelain;
  }

  &__info {
    @media (min-width: $screen-md-min) {
      min-width: 230px;
    }

    @include side-spacing(margin, left, auto);
    display: flex;
    align-items: center;
  }

  &__link {
    @include side-spacing(margin, left, auto);
    white-space: nowrap;
  }

  .flip-switch_text {
    @include font-size(regular);
  }

  .menu-button {
    @include font-size(large);
    color: $mid-gray;
    cursor: pointer;
    @include side-spacing(margin, left, auto);
    @include hidden($screen-md-min);
  }

  .custom-header-link {
    @include side-spacing(margin, right, 16px);
  }
}
