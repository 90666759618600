@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/rtl";
@import "../../../../../styles/mixins/typography";

.rule {
  &__preview {
    &__time {
      white-space: nowrap;
      margin-bottom: 8px;

      span {
        display: inline-block;
      }

      > span + span {
        font-weight: 300;
        @include side-spacing(margin, left, 8px);
      }

      &--bold {
        @include font-weight("semi-bold");
      }
    }
  }
}
