@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/typography";

.checkbox-button {
  &--checked:disabled {
    background-color: $gray-chateau;
    border-color: $gray-chateau;
  }

  @media (max-width: $screen-xs-max) {
    @include font-size(small);
    padding: 3px 8px;

    .icon {
      top: 0;
    }

    &.btn--outline--primary {
      .icon {
        color: $mid-gray;
      }
    }
  }
}
