@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";

.web-filters {
  &__header {
    @media (max-width: $screen-xs-max) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__tip {
    margin-bottom: 16px;
    @include text-caption;
  }

  &__protection-select {
    margin-bottom: 8px;

    @media (max-width: $screen-xs-max) {
      .select__toggle_input {
        border-color: var(--color-primary);
      }
    }
  }

  .protection__tool-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .tabs-select {
    width: 100%;
    @include side-spacing(margin, right, 8px);
  }
}
