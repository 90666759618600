@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/mixins/rtl";

.roaming-table {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: $screen-sm-max) {
    margin-top: 24px;
  }

  &__title {
    @extend h4;
  }

  .table__search {
    @media (max-width: $screen-xs-max) {
      flex: 100% 1;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, auto);
    }
  }

  .table-card__aside {
    align-self: center;
  }

  .roaming-counter {
    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, auto);
    }

    & + .table__search {
      @media (min-width: $screen-sm-min) {
        @include side-spacing(margin, left, 0);
      }
    }
  }
}
