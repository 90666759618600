@import "../../styles/typography";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";

.settings-page {
  height: 100%;

  &__unsaved-warning {
    @extend .color-danger;

    @media (min-width: $screen-xs-min) {
      @include side-spacing(margin, right, 8px);
    }

    @media (max-width: $screen-xxs-max) {
      margin-bottom: 4px;
    }
  }

  &__footer {
    display: flex;

    @media (min-width: $screen-xs-min) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    @media (max-width: $screen-xxs-max) {
      flex-direction: column;

      .btn {
        @include side-spacing(
          margin,
          right,
          0
        ); // todo: this overwrites .ltr .btn:not(:last-of-type) - remove after buttons are refactored

        + .btn {
          margin-top: 8px;
        }
      }
    }
  }

  &__description {
    margin-bottom: 16px;
    @media (max-width: $screen-xs-max) {
      margin-top: 8px;
    }
  }
}
