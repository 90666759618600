@import "../../../styles/colors";
@import "../../../styles/mixins/rtl";

.table {
  .header-row {
    height: 40px;
  }

  .header .sub-header-row th {
    white-space: normal;
    padding: 0;
  }

  .table__cell--right .column-sorted {
    @include side-spacing(padding, left, 12px);
    @include side-spacing(padding, right, 0);

    &:before,
    &:after {
      @include side-position(left, 0);
      @include side-position(right, auto);
    }
  }

  .column-sorted {
    &:hover {
      background-color: $geyser;
    }
  }
}
