@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/typography";

.branding {
  display: flex;
  flex-direction: column;

  &__logo,
  &__tagline,
  &__color {
    padding: 0;
  }

  &__tagline {
    margin-bottom: 40px;
  }

  &__description {
    margin-bottom: 24px;
  }

  @media (max-width: $screen-xs-max) {
    &__description {
      color: var(--color-primary);
      @include font-size(small);
    }

    &__logo,
    &__tagline,
    &__color {
      &__content {
        flex-direction: column;
      }
    }

    &__tagline,
    &__logo {
      padding-bottom: 24px;
      border-bottom: 1px solid $geyser;
      margin-bottom: 24px;
    }

    &__color {
      margin-bottom: 0;

      .picker {
        margin-bottom: 24px;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    &__color__content {
      .picker {
        @include side-spacing(margin, right, 48px);
      }
    }

    .logo-color-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  &__color {
    &__content {
      display: flex;

      .preview {
        display: flex;
        flex-direction: column;
        flex: 1;

        .color-preview {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          flex: 1;
          padding: 16px;

          background-color: $white;
          border: 1px solid $geyser;
          border-radius: 3px;

          &__circle {
            width: 88px;
            height: 88px;
            margin-bottom: 24px;
            border-radius: 50%;
            background-color: $porcelain;

            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              font-size: 54px;
            }
          }
        }
      }

      .picker__label,
      .preview__label {
        @extend .text-label;
      }
    }
  }
}
