@import "../../../styles/colors";
@import "../../../styles/variables";
@import "./sidebar";

.help-page-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;

  // TODO: extract common page header's styles
  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    color: $mine-shaft;
    padding: 20px 0 15px;
  }

  &__search {
    margin-bottom: 16px;
  }

  &__navigation {
    padding-top: 20px;
  }
}

.help-page-sidebar-wrapper {
  @include sidebar-width;
  position: fixed;
}
