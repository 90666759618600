.clear-data-dialog {
  p {
    margin-bottom: 16px;
  }

  .checkbox + .checkbox {
    margin-top: 16px;
  }

  .modal-header__text {
    text-align: left;
  }
}
