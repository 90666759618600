@import "../../../../../styles/colors";

.search-section {
  min-width: 300px;
  width: 300px;
  position: relative;

  .dropdown-menu {
    position: absolute;
    display: none;
    top: 100%;
    width: 100%;
    max-height: 296px;
    overflow-y: scroll;

    background-color: $white;
    border-radius: 3px;
    border: 1px solid $geyser;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);

    &--open {
      display: block;
    }

    &__item {
      color: $mine-shaft;
      padding: 4px;
    }
  }
}
