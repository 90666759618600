@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/layout";
@import "../../../styles/mixins/typography";

.portal-title {
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  @include side-spacing(margin, right, 10px);

  &__logo {
    max-height: 60px;
    vertical-align: middle;

    @media (max-width: $screen-xs-max) {
      max-height: 40px;
    }
  }

  &__name {
    @include font-size(x-large);
    overflow: hidden;
    width: 100%;
    color: $mid-gray;
    letter-spacing: 1px;
    @include side-spacing(margin, left, 0.3rem);
    @include side-spacing(margin, right, 1rem);

    @media (max-width: $screen-xs-max) {
      @include font-size(x-large);
      @include font-weight(light);
    }

    &--one-liner {
      @include ellipsis;
      @include adaptive(575px, 575px, 780px, "max-width");
    }
  }
}
