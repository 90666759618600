@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/layout";

.roaming-counter {
  @media (max-width: $screen-xs-max) {
    @include side-spacing(margin, left, auto);
  }

  @media (min-width: $screen-sm-min) {
    @include side-spacing(margin, right, 16px);
  }

  .tooltip-referrer {
    padding-top: 3px;
  }
}

.roaming-counter-icon {
  color: $mid-gray;
  margin-right: 4px;

  &::before {
    content: "R";
    font-size: 10px;
    position: relative;
    top: -8px;
    left: 8px;
  }
}
