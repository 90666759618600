@import "../../styles/variables";
@import "../../styles/mixins/layout";
@import "../../styles/colors";

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .layout-container {
    flex: 1 0 auto;
    height: fit-content;
    width: 100%;
    @include adaptive(750px, 970px, 1170px);
    margin: 0 auto;

    @media (max-width: $screen-xs-max) {
      padding: 0 16px;
    }
  }
}
