@import "../../../../styles/mixins/typography";
@import "../../../../styles/_colors";

.revoke-device-access-modal {
  &__description {
    margin-bottom: 4px;
  }

  &__note {
    color: $mid-gray;
    margin-bottom: 24px;
  }

  &__confirm-message {
    margin-bottom: 4px;
  }
}
