@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/typography";

$input-height: 40px;

.input {
  padding: 0;
  position: relative;

  & + & {
    margin-top: 16px;
  }

  &__label {
    @extend .text-label;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include side-position(left, 16px);
    @include font-size(regular);
    line-height: $input-height;
    color: $mid-gray;
  }

  input {
    width: 100%;
    height: $input-height;
    padding-top: 10px;
    padding-bottom: 10px;
    @include side-spacing(padding, right, 30px);
    @include side-spacing(padding, left, 16px);
    background-color: $white;
    border: 1px solid $geyser;
    border-radius: 3px;

    &:hover:not([disabled]) {
      border-color: $gray-chateau;
    }

    &:focus,
    &:active:not([disabled]) {
      border-color: var(--color-primary);
      outline: 0;
    }

    &[disabled] {
      color: $mid-gray;
      cursor: not-allowed;
      background-color: $porcelain;
      opacity: 1;
    }

    &::placeholder {
      font-style: italic;
      color: $gray-chateau;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  &__icon ~ input {
    @include side-spacing(padding, left, 40px);
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__clear {
    position: absolute;
    width: 16px;
    top: 50%;
    transform: translateY(-50%);
    @include side-position(right, 12px);
    @include font-size(medium);
    color: $boulder;
    cursor: pointer;

    &:hover {
      color: $mine-shaft;
    }

    &:hover + input {
      border-color: $gray-chateau;
    }
  }

  &__messages {
    @include text-caption;
    margin-top: 8px;

    &__info {
      display: flex;
    }

    &__error {
      color: var(--color-danger);
    }
  }

  &.has-error {
    input {
      border-color: var(--color-danger);
      box-shadow: none;

      &:focus,
      &:active {
        border-color: var(--color-danger);
        box-shadow: none;
      }
    }
  }
}
