/**
  These mixins are almost the same as on SB, but some of them are now simplified,
  and also they have different naming convention
 */

/* ===================
  Margins and Padding's
  ====================== */

@mixin side-spacing($property, $side, $value) {
  .ltr & {
    #{$property}-#{$side}: $value;
  }

  .rtl & {
    @include reverse-side-spacing($property, $side, $value);
  }
}

@mixin reverse-side-spacing($property, $side, $value) {
  @if ($side == right) {
    #{$property}-left: $value;
  } @else if ($side == left) {
    #{$property}-right: $value;
  }
}

/* =============
  Fixed Positions
  ================ */

@mixin fixed-position($side, $value) {
  .ltr & {
    #{$side}: $value;
  }

  .rtl & {
    @include reverse-fixed-position($side, $value);
  }
}

@mixin reverse-fixed-position($side, $value) {
  @if ($side == right) {
    left: $value;
  } @else if ($side == left) {
    right: $value;
  }
}

/* =================
  Text Align / Floats
  ==================== */

@mixin float-position($property, $side) {
  .ltr & {
    #{$property}: $side;
  }

  .rtl & {
    @include reverse-float-position($property, $side);
  }
}

@mixin reverse-float-position($property, $side) {
  @if ($side == right) {
    #{$property}: left;
  } @else if ($side == left) {
    #{$property}: right;
  }
}

@mixin border-radius($values) {
  $top-left: nth($values, 1);
  $top-right: nth($values, 2);
  $bottom-right: nth($values, 3);
  $bottom-left: nth($values, 4);

  .ltr & {
    border-radius: $top-left $top-right $bottom-right $bottom-left;
  }

  .rtl & {
    border-radius: $top-right $top-left $bottom-left $bottom-right;
  }
}

/* ============
  Translate Axis
  =============== */

@mixin translate-X($value) {
  .ltr & {
    transform: translateX(#{$value});
  }

  .rtl & {
    transform: translateX(calc(-1 * #{$value}));
  }
}

/* =========================
  border-position-side-radius
  ============================ */

@mixin border-position-radius($position, $side, $value) {
  .ltr & {
    border-#{$position}-#{$side}-radius: $value;
  }

  .rtl & {
    @if ($side == right) {
      border-#{$position}-left-radius: $value;
    } @else if ($side == left) {
      border-#{$position}-right-radius: $value;
    }
  }
}
