@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/layout";
@import "../../../styles/colors";
@import "../../../styles/typography";

.hm__wrapper {
  margin-bottom: 24px;

  .layout & {
    width: 100%;
    padding: 0;
  }

  border-bottom: 1px solid $geyser;

  @media (max-width: $screen-sm-max) {
    background-color: $black-haze;
  }

  .hm__primary,
  .hm__submenu {
    width: 100%;
    display: flex;
  }

  .hm__container {
    display: flex;
    align-items: center;
    @include adaptive(750px, 970px, 1170px);
  }

  .hm__submenu {
    background-color: $black-haze;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @media (max-width: $screen-md) {
      padding: 0 16px;

      .hm__item {
        height: 48px;
        line-height: 48px;

        &::before,
        &::after {
          bottom: 0;
        }
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .hm__container {
      margin: 0 auto;
    }
  }

  @media (min-width: $screen-md-min) {
    .hm__primary,
    .hm__submenu {
      justify-content: center;
    }

    .hm__submenu {
      background-color: $white-ice;
    }
  }

  .hm {
    display: flex;
    list-style: none;

    &__item {
      height: 40px;
      line-height: 40px;
      position: relative;
      letter-spacing: -0.2px;
      @include side-spacing(margin, right, 32px);
      -webkit-tap-highlight-color: transparent;

      a {
        display: inline-block;
        z-index: $zindex-dropdown;
      }

      &--active > a,
      &--active > span {
        @include text-highlighted;
      }

      &__link::before,
      &__link::after {
        content: "";
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 3px;
      }

      &__link::before {
        @include side-position(left, 0);
      }

      &__link::after {
        @include side-position(right, 0);
        background: var(--color-primary);
        transition: width 0.3s cubic-bezier(0.1, 0.1, 0.23, 1.52);
      }

      @mixin hoverMenuStyles() {
        cursor: pointer;

        &::before {
          background: var(--color-primary);
          width: 100%;
          transition: width 0.3s cubic-bezier(0.1, 0.1, 0.23, 1.52);
        }

        &::after {
          background: transparent;
          width: 100%;
          transition: 0s;
        }
      }

      &:hover .hm__item__link {
        @media (min-width: $screen-sm-min) {
          @include hoverMenuStyles();
        }
      }

      &__link--active {
        @extend .text-highlighted;
        @include hoverMenuStyles();
      }

      &__caret {
        @include side-spacing(margin, left, 8px);
        color: $boulder;
      }

      &__sub-menu-container {
        display: inline-block;
        height: 42px;
      }

      .sub-menu {
        font-weight: normal;
        display: none;
        position: absolute;
        top: calc(100% + 1px);
        min-width: 160px;
        background-color: $white;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        border-radius: 3px;
        z-index: $zindex-dropdown;
        @include side-position(left, 0);

        &::before {
          content: "";
          height: 2px;
          width: 100%;
          display: block;
          margin-top: -12px;
          background: transparent;
        }

        &--smaller-than-item {
          @include side-position(right, 0);
          @include side-position(left, auto);
        }

        &--hidden {
          visibility: hidden;
        }

        &__item {
          display: block;
          line-height: normal;
          white-space: nowrap;

          a {
            padding: 8px 16px;
            display: block;
            transition-property: all;
            transition-duration: 200ms;

            &:hover {
              background-color: $white-ice;
              color: $mine-shaft;
              cursor: pointer;
            }
          }
        }
      }

      &--sub-menu-open .sub-menu {
        display: block;
        .hm__item__link {
          &::after,
          &::before {
            content: none;
          }
        }
      }
    }

    a {
      white-space: nowrap;
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  .hm__right {
    @include side-spacing(margin, left, auto);
  }
}
