@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/mixins/colors";

.url-option {
  background-color: $white-ice;
  width: 100%;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 8px;
  @include side-spacing(padding, right, 16px);
  @include side-spacing(padding, left, 50px);
  margin-bottom: 8px;

  @media (max-width: $screen-xs-max) {
    @include side-spacing(padding, left, 34px);
  }

  &:hover {
    background-color: hsl-css(
      var(--color-primary-hs),
      calc(var(--color-primary-lightness) + 50%)
    );
  }

  &__website-details {
    display: flex;

    @media (max-width: $screen-xs-max) {
      display: flex;
      flex-flow: column nowrap;
    }
  }

  &__website-rule {
    white-space: nowrap;
    @include side-spacing(margin, right, 8px);
    @include text-highlighted;
  }

  &__website-link {
    @include ellipsis;
  }
}
