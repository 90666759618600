@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/rtl";
@import "../../../../../styles/mixins/typography";

$icon-width: 24px;
$icon-margin: 8px;

.categories-item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  text-align: initial;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: $geyser;
  }

  &__content {
    display: inline-flex;
    align-items: baseline;
  }

  &__warning {
    @include text-caption;
    margin-top: 4px;
    @media (max-width: $screen-xs-max) {
      @include side-spacing(margin, left, $icon-width + $icon-margin);
    }
  }

  .tooltip-referrer {
    @include side-spacing(margin, left, 8px);
  }

  .category-icon {
    width: $icon-width;
    height: $icon-width;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    border-radius: 3px;
    border: 1px solid $geyser;
    background-color: $white;

    @include side-spacing(margin, right, $icon-margin);
  }

  .category-info {
    color: $gray-chateau;
  }
}
