@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/rtl";
@import "../../../../../styles/mixins/typography";

.schedule__rule {
  flex-grow: 1;
  margin-bottom: 24px;
  position: relative;
  width: min-content;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &-list {
    @media (min-width: $screen-lg-min) {
      height: 584px;
    }

    overflow-y: scroll;
    position: relative;

    &__item {
      display: flex;
      padding: 16px;
      margin-bottom: 16px;
      background-color: $porcelain;
      border-radius: 3px;
      cursor: pointer;

      &--active,
      &:hover,
      &:focus {
        background-color: $geyser;
      }

      .dropdown {
        &__header {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &--opened {
          .dropdown__header {
            border: 1px solid var(--color-primary);
            border-radius: 3px;
            background-color: $geyser;
            color: $mid-gray;
          }
        }

        &__header__content {
          .icon {
            margin: 0;
          }
        }

        &__menu__item {
          white-space: nowrap;
          min-width: 112px;
        }
      }
    }
  }

  .rule__preview {
    flex: 1;
  }

  .rule__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__button {
      @include font-size(medium);
      line-height: 16px;
    }

    .remove-schedule {
      color: inherit;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}
