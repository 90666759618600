@import "../../styles/mixins/rtl";

.horizontal-scroll-tabs {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__tab {
    + .horizontal-scroll-tabs__tab {
      @include side-spacing(margin, left, 8px);
    }
  }
}
