@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.infections-list {
  display: flex;
  flex-wrap: wrap;

  .severity-level,
  .threat-id {
    display: flex;

    &:not(:last-child) {
      @include side-spacing(margin, right, 12px);

      &:after {
        @include side-spacing(margin, left, 1px);
        display: inline-block;
        content: ";";
        color: var(--color-primary);
      }
    }
  }

  .threat-id:not(:last-child) {
    @include side-spacing(margin, right, 4px);

    &:after {
      color: inherit;
    }
  }
}
