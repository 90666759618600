@import "../../../styles/colors";
@import "../../../styles/mixins/layout";
@import "../../../styles/mixins/rtl";

.web-filter-off-notification {
  @include adaptive(750px, 970px, 1170px);
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 14px 0;

  color: $white;

  &__link,
  &__link:hover {
    color: $white;
    text-decoration: underline;
    @include side-spacing(margin, left, auto);
  }

  &__content,
  &__icon {
    @include side-spacing(margin, right, 10px);
  }

  &__icon.icon {
    font-size: 1.3em;
  }

  &__link,
  &__content__description-text {
    font-weight: 300;
  }

  @media (min-width: $screen-md-min) {
    &__content__description-text:before {
      content: "\00a0";
    }
  }
  @media (max-width: $screen-md-min) {
    &__content__description-text {
      display: block;
    }
  }
  @media (max-width: $screen-sm-min) {
    &__link {
      display: block;
    }
  }
}
