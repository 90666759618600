@import "../../../../styles/mixins/typography";
@import "../../../../styles/variables";

.blocked-page {
  .section-header {
    margin-bottom: 16px;

    &__title {
      min-height: 24px;
      margin-bottom: 8px;
    }

    &__description {
      margin-bottom: 16px;

      @media (max-width: $screen-xs-max) {
        @include text-caption;
      }
    }
  }
}
