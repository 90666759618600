@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/variables";

$list-padding: 8px;
$item-top-margin: 8px;
$item-padding: 8px;

.new-requests {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border: 1px solid $geyser;
  background-color: $white;

  @media (max-width: $screen-sm-max) {
    margin-bottom: 16px;
  }

  @media (min-width: $screen-md-min) {
    min-width: 300px;
    margin-bottom: auto;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba($white-ice, 90%);

    padding: 8px 24px;

    @media (max-width: $screen-xs-max) {
      padding: 8px 16px;
    }

    &-title {
      @include text-capitalize;
    }

    &-count {
      @include side-spacing(margin, left, 6px);
    }

    &-link {
      background: transparent;
      @include side-spacing(margin, left, auto);
    }
  }

  @mixin list-height($item-height, $items-count) {
    height: ($list-padding * 2) +
      (($item-height + ($item-padding * 2) + $item-top-margin) * $items-count) -
      $item-top-margin;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $list-padding;
    line-height: initial;

    @media (max-width: $screen-xs-max) {
      @include list-height(41px, 2);
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @include list-height(20px, 2);
    }

    @media (min-width: $screen-md-min) {
      @include list-height(41px, 4);
    }

    &-item {
      display: flex;
      padding: $item-padding;
      border-radius: 3px;
      align-items: center;

      & + & {
        margin-top: $item-top-margin;
      }

      @media (max-width: $screen-sm-max) {
        &:nth-child(n + 3) {
          display: none; // show only two items on mobile and tablet
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $porcelain;
      }

      &--disabled:hover {
        cursor: not-allowed;
      }

      &__icon-container {
        @include side-spacing(margin, right, 16px);

        @media (min-width: $screen-sm-min) {
          @include side-spacing(margin, left, 8px);
        }
      }

      .icon {
        font-size: 16px;
        color: $cerulean;
      }

      &--disabled .icon {
        opacity: 0.65;
      }

      &__info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      &__date {
        @include text-caption;
        text-align: initial;
      }

      &__name {
        @include ellipsis;
        margin-bottom: 4px;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          margin-bottom: 0;
        }
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        &__info {
          width: 100%;
          flex-direction: row;

          .new-requests__list-item__date {
            @include side-spacing(margin, left, auto);
            @include side-spacing(padding, right, 8px);
          }
        }
      }
    }
  }
}
