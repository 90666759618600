@import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";

@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/layout";
@import "../../styles/mixins/typography";

.notificationWrapper {
  $notificationExtraWidth: 40px;
  $sm-breakpoint: 768px + $notificationExtraWidth;
  $md-breakpoint: 970px + $notificationExtraWidth;
  $lg-breakpoint: 1170px + $notificationExtraWidth;

  @include adaptive($sm-breakpoint, $md-breakpoint, $lg-breakpoint);
  @include adaptive(
    $sm-breakpoint/-2,
    $md-breakpoint/-2,
    $lg-breakpoint/-2,
    margin-left
  );

  &.Toastify__toast-container {
    z-index: $zindex-notification;
  }

  .Toastify__toast {
    box-shadow: 0 1px 5px 0 rgba($mine-shaft, 0.2);
    min-height: 48px;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    border-radius: 3px;

    /* override reat-toastify's rule for direction */
    .rtl & {
      direction: rtl;
    }

    &-body {
      display: flex;
      align-items: flex-start;
      margin: 0 16px;
      padding: 14px 0;
    }
  }

  .notification__icon {
    @include side-spacing(margin, right, 16px);
    margin-top: 2px;
  }

  .notification__icon--button {
    font-size: 1.2em;
    opacity: 0.9;
  }

  .notification__close-icon {
    align-self: flex-start;
    padding: 16px;
    margin: 0;
  }

  .notification__content {
    width: 100%;
    line-height: 20px;
    @include font-weight("semi-bold");
  }

  .notification__title ~ .notification__text {
    @include font-weight("regular");
  }

  @media (max-width: $sm-breakpoint) {
    top: 0;
    width: 100vw;
    margin-top: 8px;
    @include side-position(left, 0);
    @include side-spacing(margin, left, 0);

    .Toastify__toast {
      width: calc(100% - 16px);
      left: 8px;
    }
  }

  @media (min-width: $sm-breakpoint) {
    &.scrolled {
      top: 1em;
    }
  }
}

.Toastify__toast--success {
  background: var(--color-success);
}

.Toastify__toast--info {
  background: var(--color-primary);
}

.Toastify__toast--warning {
  background: var(--color-warning);
}

.Toastify__toast--error {
  background: var(--color-danger);
}

.Toastify__close-button {
  align-self: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}
