@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/colors";
@import "../../styles/buttons";

//theme, color, bg-color, hover-bg-color, active-bg-color, disabled-bg-color
$primary: (
  "primary",
  $white,
  var(--color-primary),
  var(--color-primary-hover),
  var(--color-primary-pressed),
  var(--color-primary-disabled)
);
$destructive: (
  "destructive",
  $white,
  var(--color-danger),
  var(--color-danger-hover),
  var(--color-danger-pressed),
  var(--color-danger-disabled)
);
//theme, color, bg-color, border-color, hover-bg-color, active-bg-color
$outline-primary: (
  "primary",
  var(--color-main),
  $white,
  $gray-chateau,
  $geyser,
  darken($geyser, 10%)
);
$outline-destructive: (
  "destructive",
  var(--color-main),
  $white,
  var(--color-danger),
  var(--color-danger-lightests),
  var(--color-danger-light)
);

.btn {
  -webkit-tap-highlight-color: transparent;

  @each $theme, $color, $bg-color, $hover-bg-color, $active-bg-color,
    $disabled-bg-color in $primary, $destructive
  {
    &--#{$theme} {
      &.btn--solid {
        @include create-button-variant(
          $color,
          $bg-color,
          $hover-bg-color,
          $active-bg-color,
          $disabled-bg-color
        );
      }
    }
  }

  @each $theme, $color, $bg-color, $border-color, $hover-bg-color,
    $active-bg-color in $outline-primary, $outline-destructive
  {
    &--#{$theme} {
      &.btn--outline {
        @include create-outline-button-variant(
          $color,
          $bg-color,
          $border-color,
          $hover-bg-color,
          $active-bg-color
        );
      }
    }
  }

  &--solid,
  &--outline {
    @include create-button(2px, 16px, 3px);
  }

  &--pill {
    $pill-outline-primary-active-bg: hsl(
      $brand-primary-hue,
      $brand-primary-saturation,
      $brand-primary-lightness + 50%
    );

    @include create-button(2px, 16px, 16px);

    &.btn--outline {
      @include create-pill-button-variant(
        var(--color-main),
        $white,
        var(--color-primary),
        $pill-outline-primary-active-bg,
        var(--color-primary-pressed)
      );

      &:active:not(:disabled) {
        color: $white;
      }
    }
  }

  &--sm {
    height: 24px;
    &:not(:last-of-type) {
      @include side-spacing(margin, right, 16px);
    }
  }

  &--md {
    height: 32px;
    padding: 6px 16px;

    &:not(:last-of-type) {
      @include side-spacing(margin, right, 12px);
    }
  }

  &--lg {
    height: 40px;
    padding: 10px 16px;

    &:not(:last-of-type) {
      @include side-spacing(margin, right, 8px);
    }
  }
}
