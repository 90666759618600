$black: #000000;
$mine-shaft: #212121;
$mid-gray: #65666a;
$boulder: #7a7a7a;
$gray-chateau: #a5aeb2;
$geyser: #d3dce1;
$porcelain: #eceeef;
$white-ice: #f2fafd;
$mystic: #e9eef0;
$black-haze: #f9fafa;
$white: #ffffff;

$alizarin-crimson: #e02f1e;
$thunderbird: #be2516;
$outrageous-orange: #ff5a34;
$yellow-sea: #ffa40a;

$eucalyptus: #27965e;
$shamrock: #33c67c;
$granny-apple: #d6f5e5;

$seagull: #81d1ec;
$cerulean: #02a2d8;

//can be removed once flipswitch and buttons are refactored to use hsla
$allports: #007796;
$sky-blue: #7dd1ed;

$brand-primary: $cerulean;
$brand-success: $shamrock;
$brand-warning: $yellow-sea;
$brand-danger: $alizarin-crimson;

//HSL
$brand-primary-hue: hue($brand-primary);
$brand-primary-saturation: saturation($brand-primary);
$brand-primary-lightness: lightness($brand-primary);
$brand-primary-hs: $brand-primary-hue, $brand-primary-saturation;

$brand-danger-hue: hue($brand-danger);
$brand-danger-saturation: saturation($brand-danger);
$brand-danger-lightness: lightness($brand-danger);
$brand-danger-hs: $brand-danger-hue, $brand-danger-saturation;

$brand-success-hue: hue($brand-success);
$brand-success-saturation: saturation($brand-success);
$brand-success-lightness: lightness($brand-success);
$brand-success-hs: $brand-success-hue, $brand-success-saturation;
