@import "../../../../../styles/colors";
@import "../../../../../styles/typography";

.protection-editor {
  &__block-category-button {
    display: block;
    margin: 40px auto 0;
    width: 300px;
    font-size: 1.23rem;
    font-weight: 400;
  }

  &__list-container {
    height: 344px;
    background-color: $black-haze;
    overflow-y: scroll;
    padding: 8px;
  }

  &__options {
    height: 48px;
    background-color: $porcelain;
    display: flex;
    padding: 8px 16px;
    align-items: center;

    .filter-section {
      &__title {
        @include side-spacing(margin, right, 8px);
      }

      .filter-button span {
        @include text-highlighted;
        @include side-spacing(margin, left, 8px);
      }
    }

    .action-section {
      @include side-spacing(margin, left, auto);
    }
  }

  .categories-count {
    font-weight: 600;
    display: inline-block;
  }
}
