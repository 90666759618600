@import "../../styles/typography";

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 80px;

  &__icon {
    margin-bottom: 24px;
  }

  &__title {
    @extend h4;
    margin-bottom: 16px;
  }

  &__message {
    .btn {
      margin-top: 24px;
    }
  }
}
