@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/typography";

.home-page__barchart {
  position: relative;

  .malware-bars {
    position: relative;
    bottom: 60px;
    transform: scaleY(-1);
  }

  .scrollable-container,
  .width-container {
    width: 100%;
  }

  .scrollable-container {
    margin-top: 8px;
    height: 235px;
  }

  .recharts-label {
    @include font-size(regular);
    fill: $boulder;
  }

  @media (max-width: $screen-xs-max) {
    .time-period__info {
      margin-bottom: 16px;
    }

    .scrollable-container {
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      margin: 0 -15px;
      width: calc(100% + 30px);
    }

    .width-container {
      width: 200%;
      padding: 0 15px;
    }

    .recharts-tooltip-wrapper {
      display: none;
    }
  }

  .legend {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__item {
      &:not(:last-child) {
        @include side-spacing(margin, right, 32px);
      }

      &:before {
        display: inline-block;
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 3px;
        vertical-align: middle;
        @include side-spacing(margin, right, 8px);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--activity:before {
        background-color: var(--color-report-activity);
      }

      &--blocked:before {
        background-color: var(--color-report-webfilter);
      }

      &--malware:before {
        background-color: var(--color-report-malware);
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .legend {
      flex-direction: row;
      margin-top: 24px;
    }
  }

  @media (max-width: $screen-xs-max) {
    padding-bottom: 24px;
    border-bottom: 1px solid $geyser;

    .legend__item:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
