@import "../../../../styles/mixins/rtl";
@import "../../../../styles/variables";
@import "../../../../styles/colors";

:root {
  --eula-dialog-header-font-size: 20px;
  --eula-dialog-header-line-height: 30px;
  --eula-dialog-header-text-color: #{$mine-shaft};

  --eula-dialog-body-font-size: 14px;
  --eula-dialog-body-line-height: 22px;
  --eula-dialog-body-text-color: #{$mine-shaft};

  --eula-dialog-footer-font-size: 14px;
  --eula-dialog-footer-line-height: 20px;
  --eula-dialog-footer-cancel-button-color: #fff;
  --eula-dialog-footer-cancel-button-border-color: #{$gray-chateau};
  --eula-dialog-footer-cancel-button-text-color: #{$mine-shaft};
  --eula-dialog-footer-accept-button-color: #{$brand-primary};
  --eula-dialog-footer-accept-button-border-color: #{$brand-primary};
  --eula-dialog-footer-accept-button-text-color: #fff;
}

.modal__dialog.eula-dialog {
  .modal {
    &__header {
      font-size: var(--eula-dialog-body-font-size);
      line-height: var(--eula-dialog-body-line-height);
      color: var(--eula-dialog-body-text-color);
    }

    &__body {
      white-space: pre-wrap;
      @include side-spacing(padding, right, 8px);
      font-size: var(--eula-dialog-body-font-size);
      line-height: var(--eula-dialog-body-line-height);
      color: var(--eula-dialog-body-text-color);

      @media (min-width: $screen-sm-min) {
        overflow-y: auto;
        max-height: 250px;
      }
    }

    &__footer {
      .modal__control {
        font-size: var(--eula-dialog-footer-font-size);
        line-height: var(--eula-dialog-footer-line-height);

        &:first-child {
          border-color: var(--eula-dialog-footer-cancel-button-border-color);
          background-color: var(--eula-dialog-footer-cancel-button-color);
          color: var(--eula-dialog-footer-cancel-button-text-color);
        }

        &:last-child {
          border-color: var(--eula-dialog-footer-accept-button-border-color);
          background-color: var(--eula-dialog-footer-accept-button-color);
          color: var(--eula-dialog-footer-accept-button-text-color);
        }
      }
    }
  }
}
