@import "../../../../styles/colors";
@import "../../../../styles/variables";

.global-url-filters-page {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 16px;
  }

  &__container {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: $screen-xs-max) {
    margin-top: 15px;
    padding: 0 15px;
  }
}
