@import "../../../styles/variables";

.widget-line-chart {
  height: 66px; // preserve the space until svg is rendered

  @media (max-width: $screen-xs-max) {
    position: relative;
    top: 6px;

    svg {
      max-height: 60px;
    }
  }
}

@mixin gradient($mainColor) {
  stop:first-child {
    stop-color: $mainColor;
    stop-opacity: 0.4;
  }

  stop:last-child {
    stop-color: #fff;
    stop-opacity: 0;
  }
}

#webfilter_line {
  stroke: var(--color-report-webfilter);
}
#webfilter_gradient {
  @include gradient(var(--color-report-webfilter));
}

#malware_line {
  stroke: var(--color-report-malware);
}
#malware_gradient {
  @include gradient(var(--color-report-malware));
}

#botnet_line {
  stroke: var(--color-report-botnet);
}
#botnet_gradient {
  @include gradient(var(--color-report-botnet));
}
