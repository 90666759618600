@import "../../../../styles/colors";

.add-device-link {
  & .icon {
    color: $mid-gray;
  }

  .mobile-menu & {
    color: inherit;
  }
}
