@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.device-with-type {
  .icon {
    color: $mid-gray;
  }

  .protection-status .icon {
    color: inherit;
  }

  .device-identifier {
    @include side-spacing(margin, left, 8px);
  }
}
