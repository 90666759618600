@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.dropdown {
  &__menu {
    padding: 0;
    z-index: $zindex-dropdown;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 2px 8px 0 rgba(33, 33, 33, 0.16);
    color: $mine-shaft;

    a,
    a:hover {
      color: inherit;
    }
  }

  &__item {
    padding: 8px 16px;
    @include text-align(left);
    cursor: pointer;

    .icon {
      @include side-spacing(padding, right, 8px);
    }

    &--highlighted {
      color: $white;
      background-color: var(--color-primary);

      .icon {
        color: $white;
      }
    }

    &--divider {
      height: 1px;
      background-color: $porcelain;
      padding: 0;
      margin: 5px 0;
      border: 0;
      pointer-events: none;
    }

    &--title {
      padding-bottom: 0;
      cursor: text;
    }

    &--disabled {
      .icon {
        color: inherit;
      }
    }
  }
}
