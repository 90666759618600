@import "../../../../../styles/typography";

.categories-list {
  // 225px for column will give 3 columns for multiple and 4 columns for single profiles
  column-width: 225px;

  &__error {
    @extend .color-danger;
  }
}
