@import "../../../styles/colors";
@import "../../../styles/variables";

.layout .static-notifications {
  width: 100%;

  @media (max-width: $screen-sm-min) {
    padding: 0;
  }
}

.static-notifications {
  &__web-filter-off {
    background: $yellow-sea;

    @media (max-width: $screen-sm-min) {
      padding: 0 15px;
    }
  }
}
