@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/typography";
@import "../../../styles/colors";
@import "../../../styles/variables";

.time-period {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  .select-wrapper {
    width: 120px;
    @include side-spacing(margin, right, 16px);
  }

  &__info {
    white-space: nowrap;
  }

  .select__menu {
    @include side-position(right, 0);
    border: none;

    &__item {
      padding: 6px 16px;
      white-space: nowrap;
    }
  }

  @media (min-width: $screen-md-min) {
    &__info {
      @include text-highlighted;
    }
  }

  @media (max-width: $screen-sm-max) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 16px;

    &__info {
      align-self: stretch;
      background-color: $porcelain;
      margin: 16px -15px 0;
      padding: 8px 15px;
      flex: 1 1 100%;
      font-weight: 600;
    }
  }
}
