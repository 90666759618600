@import "../../../../../styles/colors";
@import "../../../../../styles/typography";
@import "../../../../../styles/mixins/rtl";
@import "../../../../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";
@import "../../../../../styles/mixins/typography";

.schedule-rules-overlay {
  .cross-button {
    font-size: 20px;
  }

  .modal-header {
    position: relative;
    justify-content: center;
    align-items: center;

    .icon {
      position: absolute;
      left: 16px;
    }
  }

  .modal-body {
    margin-bottom: 0;
  }

  &__title {
    @extend h4;
    max-width: 80%;
    text-align: center;
    display: flex;
  }

  &__back {
    @include side-spacing(margin, left, -16px);
    -webkit-tap-highlight-color: transparent;
    padding: 16px;

    .icon:before {
      content: fa-content($fa-var-angle-left);
      font-size: 24px;

      .rtl & {
        content: fa-content($fa-var-angle-right);
      }
    }
  }

  &__counter {
    margin-bottom: 4px;

    .counter__label,
    .counter__amount {
      @include font-size(regular);
      @include font-weight(light);
    }
  }

  .rule {
    display: flex;
    justify-content: space-between;
    -webkit-tap-highlight-color: transparent;
    padding: 16px;
    margin-bottom: 16px;
    background-color: $porcelain;
    border-radius: 3px;
    cursor: pointer;

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__button {
        @include font-size(medium);
        line-height: 16px;
      }
    }
  }

  .remove-schedule {
    color: inherit;

    &[disabled] {
      opacity: 0.5;
    }
  }
}
