@import "../../styles/colors";

.settings-btn {
  display: inline-flex;
  justify-content: space-between;

  &__status {
    color: $cerulean;
  }

  .icon {
    margin: 0 5px;
  }
}
