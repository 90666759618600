@import "../../styles/mixins/rtl";
@import "../../styles/variables";

.sticky-sidebar {
  position: relative;

  &__header {
    margin-bottom: 8px;
  }

  &__tabs {
    overflow-y: auto;
    position: absolute;
    width: 220px;

    @media (min-width: $screen-lg-min) {
      width: 270px;
    }
  }

  &__container {
    &--sticky {
      position: fixed;
      overflow-y: auto;
      @include side-spacing(padding, right, 15px);

      .sticky-sidebar__tabs {
        position: static;
        overflow-y: auto;
      }
    }
  }
}
