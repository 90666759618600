@import "../../styles/colors";
@import "../../styles/mixins/rtl";

.severity-level {
  position: relative;

  &:before {
    content: "";

    position: absolute;
    @include side-position(left, 0);
    top: 8px;

    width: 8px;
    height: 8px;

    border-radius: 50%;
  }

  &--1,
  &--2,
  &--3,
  &--4 {
    @include side-spacing(padding, left, 16px);
  }

  &--1:before {
    background-color: $gray-chateau;
  }

  &--2:before {
    background-color: $seagull;
  }

  &--3:before {
    background-color: $yellow-sea;
  }

  &--4:before {
    background-color: $thunderbird;
  }
}
