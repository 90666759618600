@import "~@fortawesome/fontawesome-pro/css/all.css";
@import "~bootstrap/scss/bootstrap-grid.scss";

@import "./styles/reset";
@import "./styles/fonts";
@import "./styles/colors";
@import "./styles/mixins/colors";
@import "./styles/forms";
@import "./styles/indents";
@import "./styles/typography";

:root {
  --dark: -10%;
  --darken: -20%;
  --light: 20%;
  --lighter: 30%;
  --lightests: 40%;

  --color-main: #{$mine-shaft};

  --color-primary: #{$brand-primary};
  --color-primary-hs: #{$brand-primary-hs};
  --color-primary-lightness: #{$brand-primary-lightness};
  --color-primary-hover: hsl(
    var(--color-primary-hs),
    calc(var(--color-primary-lightness) + var(--dark))
  );
  --color-primary-pressed: hsl(
    var(--color-primary-hs),
    calc(var(--color-primary-lightness) + var(--darken))
  );
  --color-primary-disabled: hsl(
    var(--color-primary-hs),
    calc(var(--color-primary-lightness) + var(--lighter))
  );

  --color-text-highlight: hsl(
    var(--color-primary-hs),
    calc(var(--color-primary-lightness) + var(--lighter))
  );

  --color-danger: #{$brand-danger};
  --color-danger-hs: #{$brand-danger-hs};
  --color-danger-lightness: #{$brand-danger-lightness};
  --color-danger-hover: hsl(
    var(--color-danger-hs),
    calc(var(--color-danger-lightness) + var(--dark))
  );
  --color-danger-pressed: hsl(
    var(--color-danger-hs),
    calc(var(--color-danger-lightness) + var(--darken))
  );
  --color-danger-disabled: hsl(
    var(--color-danger-hs),
    calc(var(--color-danger-lightness) + var(--light))
  );
  --color-danger-light: hsl(
    var(--color-danger-hs),
    calc(var(--color-danger-lightness) + var(--lighter))
  );
  --color-danger-lightests: hsl(
    var(--color-danger-hs),
    calc(var(--color-danger-lightness) + var(--lightests))
  );

  --color-warning: #{$brand-warning};
  --color-success: #{$brand-success};
  --color-success-hs: #{$brand-success-hs};
  --color-secondary: #{$boulder};
  --color-disabled: #{$mid-gray};

  --color-controls-border: #{$geyser};
  --color-controls-border-hover: #{$gray-chateau};

  --color-control-hover: #{$white-ice};

  --color-select-item-hover: #{$porcelain};

  --color-report-activity: #b9e3fe;
  --color-report-webfilter: #{$shamrock};
  --color-report-webfilter-brushed: hsl(
    var(--color-success-hs),
    calc(#{$brand-success-lightness} - 19%)
  );
  --color-report-malware: #{$outrageous-orange};
  --color-report-malware-brushed: #{$thunderbird};

  --color-report-botnet: #16303e;
  --color-report-botnet-brushed: #007796;

  --color-report-brush-selection: #{$geyser};
  --color-report-brush-selection-border: #{$cerulean};
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  @include font-size(regular);
}

body {
  display: flex;
  flex-direction: column;

  //To make fallback font match the operating system,
  // you’ll need to list the system fonts for all operating
  // systems in a specific order.

  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", Arial, sans-serif;
  color: var(--color-main);

  //Helps to avoid unexpected font-size scaling;
  // reproduces on orientation change to landscape
  text-size-adjust: none;
}

button,
input,
optgroup,
select,
textarea {
  @include font-size(regular);
  font-family: "Open Sans", Arial, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}
