@import "../../styles/mixins/rtl";
@import "../../styles/colors";
@import "../../styles/variables";

.devices {
  &__layout {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .horizontal-scroll-tabs {
      flex: 100%;
      margin-bottom: 16px;
    }
  }

  .tab-list {
    @include side-spacing(margin, right, auto);

    @media (min-width: $screen-sm-min) {
      flex: 100% 1;
      margin-bottom: 16px;
    }
  }

  .table__search {
    padding-bottom: 0;
    margin-bottom: 0;

    @media (max-width: $screen-xs-max) {
      flex: 100%;
      margin-bottom: 16px;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, auto);
    }
  }

  &__refresh {
    .btn {
      width: 100%;
    }

    @media (max-width: $screen-xs-max) {
      flex: 100%;
      margin-bottom: 16px;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, right, 8px);
    }
  }
}
