@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";

.spson-info {
  background-color: $white-ice;
  border-radius: 3px;
  padding: 16px 24px 24px;

  @media (max-width: $screen-sm-max) {
    margin-bottom: 16px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    text-align: center;
  }

  @media (max-width: $screen-xs-max) {
    display: flex;
    align-items: center;
    padding: 16px;
  }

  &__title {
    @include font-weight(semi-bold);
    margin-bottom: 8px;

    @media (min-width: $screen-sm-min) {
      @include font-size(x-large);
      display: flex;
      justify-content: center;
    }

    @media (min-width: $screen-md-min) {
      justify-content: normal;
    }

    @media (max-width: $screen-xs-max) {
      @include font-size(large);
    }
  }

  &__description {
    @media (min-width: $screen-md-min) {
      margin-bottom: 8px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin: 0 auto 16px;
      width: 85%;
    }

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &__actions {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      position: relative;
      width: 225px;
      margin: 0 auto;
    }

    @media (max-width: $screen-xs-max) {
      @include side-spacing(margin, left, auto);
    }
  }

  &__more-info {
    @media (min-width: $screen-md-min) {
      margin-bottom: 48px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      position: absolute;
      @include side-position(left, 241px);
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
    }
  }

  &__button {
    .btn {
      width: 100%;
    }

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  .icon {
    @include side-spacing(margin, right, 8px);
    color: var(--color-primary);

    &--spson {
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
  }
}
