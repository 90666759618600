@import "../../../../styles/mixins/rtl";
@import "../../../../styles/colors";
@import "../../../../styles/typography";

.check-url-block {
  &-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;

    &--has-error {
      align-items: center;
    }

    &__input {
      flex: 1 1 auto;
    }

    &__button {
      @include side-spacing(margin, left, 15px);
      height: 40px;
      flex: 1 1 auto;

      @media (min-width: $screen-sm-min) {
        max-width: 140px;
      }
    }

    @media (max-width: $screen-xs-max) {
      & input,
      &__button {
        width: 100%;
        height: 50px;
      }

      &__button {
        @include side-spacing(margin, left, 0);
        margin-top: 16px;
      }
    }

    .error-msg {
      color: var(--color-danger);
    }

    .icon {
      color: var(--color-primary);
    }
  }

  .check-url__options {
    margin-top: 16px;
  }

  .toggle-btn {
    @include text-align(left);
    @include side-spacing(margin, right, 16px);
  }
}
