@import "../../styles/colors";
@import "../../styles/mixins/rtl";
@import "../../styles/mixins/layout";
@import "../../styles/variables";

.domain-lookup {
  &__header {
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 24px;
  }

  &__search-form {
    display: flex;
  }

  &__input {
    flex: 1 0 auto;
  }

  &__search-button {
    height: 40px;
    margin-top: 29px;

    @include side-spacing(margin, left, 16px);
    flex: 0 0 auto;

    @include adaptive(136.66px, 131.66px, 165px);
  }

  &__table {
    margin-top: 30px;

    .table__cell__header {
      display: none;
    }

    .table__cell__content {
      width: 100%;
      @include text-align(left);
    }
  }

  &__table-cell {
    display: flex;
    align-items: flex-start;
    white-space: normal;
    word-break: break-word;

    .category__icon {
      flex: none;
    }

    .category__status {
      @include side-spacing(margin, left, 8px);
      flex: 1;
    }
  }

  @media (max-width: $screen-xs-max) {
    &__search-form {
      flex-direction: column;
    }

    &__input,
    &__search-button {
      width: 100%;
    }

    &__search-button {
      @include side-spacing(margin, left, 0);
      margin-top: 10px;
    }

    &__table {
      .-odd,
      .-even {
        border: none;

        .table__cell {
          padding: 0;
          color: $mine-shaft;
        }
      }
    }
  }
}
