@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/typography";
@import "../../../styles/colors";
@import "../../../styles/variables";

.spson-panel {
  $panel: &;

  display: flex;
  flex-direction: column;

  width: 296px;
  padding: 16px 24px 24px;
  border-radius: 3px;

  background-color: $white-ice;

  &__header {
    font-weight: 600;
  }

  &__description {
    line-height: 24px;
  }

  .rtl & {
    .fa-arrow-circle-right:before {
      content: "\f0a8";
    }
  }

  &--fluid {
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 8px 16px;
    border: 1px solid var(--color-primary);

    #{$panel}__header {
      @include font-size(medium);
      @include side-spacing(margin, right, 16px);
    }

    #{$panel}__cta {
      height: 32px;
      padding: 4px 24px;
      @include side-spacing(margin, left, auto);
    }

    @media (max-width: $screen-xs-max) {
      flex-wrap: wrap;
      padding: 16px 8px;

      #{$panel}__header {
        width: 100%;
        margin-bottom: 16px;
      }

      #{$panel}__cta {
        padding: 4px 16px;
      }
    }
  }

  &--sidebar {
    #{$panel}__header {
      font-size: 20px;
      line-height: 32px;
    }

    #{$panel}__header,
    #{$panel}__description {
      margin-bottom: 8px;
    }

    #{$panel}__extra-info {
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
}
