@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/layout";

.contact-info-footer {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  background-color: $cerulean;

  .layout & {
    width: 100%;
  }

  &__wrapper {
    @include adaptive(750px, 970px, 1170px);
    padding: 15px 0;

    @media (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
    }
  }

  &__item {
    display: flex;
    flex: 1 1 auto;
    color: $white;
  }

  &__icon {
    height: 40px;
    @include side-spacing(margin, right, 8px);
  }

  &__link,
  &__link:hover,
  &__link:visited,
  &__link:active {
    color: $white;
  }
}
