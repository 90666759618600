@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";

.help-search {
  + .help-page__content {
    padding-top: 57px;
  }

  @media (max-width: $screen-xs-max) {
    position: fixed;
  }

  &__controls {
    display: flex;
    flex-direction: row;
  }

  &__input {
    flex: 1 0 auto;
  }

  &__nav-button {
    flex: 0 1 30px;
    padding: 0;
    // TODO: rework, currently it is for isolate overriding default .btn styles that might not supposed to be default
    @include oneside(margin, left, 5px!important);
    @include oneside(margin, right, 0px!important);
  }

  &__message {
    flex: 1 0 100%;
    padding: 8px;
    margin-top: 8px;
    border-radius: 3px;
    font-size: 1rem;
    // originally here bootstrap's alert colors were used,
    // but since they seem not used anywhere else they were replaced with the closest existing color
    color: $yellow-sea;
    background-color: transparentize($yellow-sea, 0.9);

    @media (max-width: $screen-xs-max) {
      margin-top: 15px;
    }
  }
}
