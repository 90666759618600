@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/rtl";
@import "../../../../../styles/mixins/typography";
@import "../../../../../styles/colors";

.time-grid {
  position: relative;
  height: 584px;
  flex: 1 0 526px;
  margin: 32px;
  background-color: $white;

  &__hour-axis {
    position: absolute;
    top: -6px;
    height: calc(100% + 12px);
    @include font-size(small);
    @include side-position(left, -24px);
    @include text-align(right);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__day-axis {
    @include font-size(small);
    position: absolute;
    top: -20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  &__grid {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 100%;

    &__column {
      position: relative;
      border: $porcelain 1px solid;
      flex: 1 1 auto;
      padding: 3px;
      @include oneside(border, left, none);

      &:first-child {
        @include oneside(border, left, $porcelain 1px solid);

        .ltr & {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        .rtl & {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      &:last-child {
        .rtl & {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        .ltr & {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      &--highlighted {
        background-color: $black-haze;
      }
    }

    .tooltip-referrer {
      position: absolute;
      width: calc(100% - 8px);

      .time-grid__box {
        height: 100%;
        width: 100%;
        opacity: 0.5;
        border-radius: 3px;
        background-color: $porcelain;
        border: 1px solid $mid-gray;
        cursor: pointer;

        &:hover,
        &--active {
          background-color: $geyser;
          border: 1px solid $cerulean;
        }
      }
    }
  }

  &__hour-line-layer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }

  &__hour-line {
    flex: 1 1 auto;
    border-bottom: 1px dashed $mid-gray;

    &:last-child {
      border: none;
    }
  }
}
