@import "../../../styles/colors";
@import "../../../styles/variables";

.page-name {
  @media (max-width: $screen-sm-max) {
    border-top: 1px solid $geyser;
  }

  &__content {
    color: var(--color-primary);
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 24px 16px;

    h1 {
      margin-bottom: 0;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      margin: 0 auto;
      width: 750px;
      padding: 16px 0;
    }
  }
}

.hm__page-name__portal--with-sub-menu .page-name__content {
  padding-bottom: 8px;
}
