@import "../../../styles/mixins/ellipsis";

.select-menu__item {
  width: 100%;
  cursor: pointer;
  @include ellipsis;

  &[disabled],
  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}
