@import "../../styles/colors";
@import "../../styles/typography";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";

$height: 40px;
@mixin selectIcon($icon) {
  color: $boulder;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: fa-content($icon);
}

@mixin arrowDown() {
  @include selectIcon($fa-var-angle-down);
}

@mixin arrowUp() {
  @include selectIcon($fa-var-angle-up);
}

.native-select {
  position: relative;
  width: 100%;

  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  &__label {
    @extend .text-label;
  }

  &__toggle {
    position: relative;
    border-radius: 3px;
    padding: 0 16px;
    border: 1px solid $geyser;
    color: $mine-shaft;
    background-color: $white;

    height: $height;
    line-height: $height;

    &__content {
      display: flex;
      @include ellipsis;

      > span {
        flex: 1;
      }

      .icon {
        color: inherit;
      }

      .icon:after {
        @include arrowDown();
        @include side-spacing(margin, left, 5px);
      }

      button {
        width: 100%;
      }

      .btn__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
