@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";
@import "../../../../styles/typography";
@import "../../../../styles/variables";

.url-options-picker {
  &__tld-info {
    margin-bottom: 16px;
  }

  &__tip {
    margin-bottom: 16px;
  }

  &__warning {
    @include text-caption;
    @include side-spacing(margin, left, 8px);
    max-width: 485px;

    @media (max-width: $screen-xs-max) {
      margin-top: 8px;
      @include side-spacing(margin, left, 0);
      max-width: fit-content;
      width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__action-box {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  &__action-buttons {
    display: flex;

    @media (max-width: $screen-xs-max) {
      button {
        height: 40px;
        flex: 0 1 100%;
      }
    }
  }
}
