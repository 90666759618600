@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";

.add-device-modal {
  .tab-item {
    flex: 1;
  }

  .tab-control {
    width: 100%;
  }

  &__tip {
    margin: 16px 0;

    a + p {
      margin-top: 8px;
    }
  }

  &__code-info {
    margin: 15px 0;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;

    background-color: $black-haze;

    display: flex;

    &--found {
      color: var(--color-success);
      @include side-spacing(margin, left, auto);
    }

    &--not-found {
      color: var(--color-warning);
    }

    &--exists {
      color: var(--color-danger);
      @include side-spacing(margin, left, auto);
    }
  }

  .input-with-search {
    position: relative;

    .icon {
      display: none;
    }

    &.searching {
      .icon {
        display: block;
        position: absolute;
        bottom: 6px;
        @include side-position(right, 15px);
      }
    }
  }

  .select__menu {
    z-index: $zindex-modal;
  }
}
