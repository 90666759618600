@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/rtl";

.schedule-days {
  .checkbox {
    margin-bottom: 16px;
  }

  @media (min-width: $screen-sm-min) {
    margin-bottom: 16px;

    .checkbox {
      display: inline-block;

      &:not(:last-of-type) {
        @include side-spacing(margin, right, 16px);
      }
    }
  }
}
