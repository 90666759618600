@import "../../../../styles/variables";

.drilldown-barchart {
  position: relative;
  width: 100%;
  margin-top: 16px;

  .tooltip {
    word-break: break-all;
  }

  .width-container {
    width: 100%;
  }

  @media (max-width: $screen-xs-max) {
    overflow-x: scroll;
    overflow-y: hidden;
    overflow-scrolling: touch;
    margin: 16px -15px;
    width: calc(100% + 30px);

    .width-container {
      width: 200%;
      padding: 0 15px;
    }

    .recharts-tooltip-wrapper {
      display: none;
    }
  }

  .barchart-container {
    height: 260px;
  }
}
