@import "../../../styles/mixins/layout";
@import "../../../styles/mixins/typography";
@import "../../../styles/variables";
@import "../../../styles/colors";

.footer {
  @include font-size(small);
  position: relative;
  width: 100%;
  z-index: 5;
  background: $white;
  border-top: 1px solid $geyser;

  &_content {
    padding: 24px 0;
    text-align: left;
    @include adaptive(750px, 970px, 1170px);
    margin: 0 auto;

    &__block {
      display: inline-block;
    }
  }

  @media (max-width: $screen-xs-max) {
    &_content {
      padding: 16px;
    }
  }

  @media (max-width: $screen-sm-max) {
    margin-top: 32px;
  }

  @media (min-width: $screen-md-min) {
    border: 0;
    padding-top: 52px;

    &_content {
      border-top: 1px solid $geyser;
    }
  }
}
