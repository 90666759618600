.cross-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &--small {
    height: 24px;
    width: 24px;
  }
  &--medium {
    height: 40px;
    width: 40px;
  }
  &--large {
    height: 48px;
    width: 48px;
  }
}
