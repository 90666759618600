@import "../../../../styles/variables";

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .devices--per-profile {
    .devices__tabs {
      flex: initial;
      margin-bottom: initial;
    }
  }
}
