@import "../../../styles/colors";

.app-status {
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  border-radius: 3px;
  padding: 2px 6px;
  background-color: $granny-apple;
  color: $eucalyptus;

  &--disabled {
    background-color: $geyser;
    color: $mid-gray;
  }
}
