@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/rtl";

@mixin sidebar-tab-header($class) {
  position: relative;
  @include side-spacing(padding, right, 0);
  border-radius: 3px;
  background-color: $porcelain;
  $classActive: #{$class}--active;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:not(#{$classActive}):hover {
    background-color: $geyser;
    cursor: pointer;
  }

  &--active {
    background-color: var(--color-primary);
    color: $white;
  }
}

.sidebar {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 8px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      align-items: normal;
      flex-direction: column;
    }

    &--main {
      margin-bottom: 24px;
      margin-top: 8px;
    }
  }

  &__tab-header {
    @include sidebar-tab-header(&);
  }

  &__content {
    display: none;

    .rtl & {
      border-left: none;
      border-right: 1px solid $porcelain;
    }

    &--show {
      display: block;
    }
  }
}
