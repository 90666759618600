@import "../../../../styles/colors";
@import "../../../../styles/variables";

.blocked-page {
  .admin-info {
    margin-bottom: 32px;

    &__field {
      @media (min-width: $screen-sm-min) {
        width: 264px;
      }

      .icon {
        font-size: 14px;
      }
    }
  }
}
