@import "../../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";
@import "../../../../node_modules/@fortawesome/fontawesome-pro/scss/mixins";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/typography";

.table-card {
  display: flex;
  align-items: center;

  &__expand-arrow {
    @include side-spacing(padding, right, 8px);

    @media (max-width: $screen-xs-max) {
      padding: 8px;
      @include side-spacing(padding, right, 16px);
    }

    &:before {
      @include fa-icon();
      font-family: "Font Awesome 5 Pro";
      font-weight: 600;
      visibility: visible;
      display: inline-block;
      font-size: 14px;
      width: 16px;
      height: 16px;
      content: fa-content($fa-var-caret-right);
      color: $mine-shaft;
      text-align: center;
    }

    .rtl &:before {
      content: fa-content($fa-var-caret-left);
    }

    &.table-card__expand-arrow__expanded:before {
      content: fa-content($fa-var-caret-down);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 2;

    @include ellipsis;
    @include side-spacing(padding, right, 8px);

    > span:not(.device-name),
    .device-with-type {
      @include ellipsis;
    }

    .device-name {
      display: block;
      white-space: pre-line;
      word-break: break-word;
    }
  }
}
