@import "../../../../styles/mixins/rtl";
@import "../../../../styles/colors";
@import "../../../../styles/typography";

.upload-csv {
  margin: 0 0 16px;

  &__container {
    display: flex;
    margin-bottom: 8px;
  }

  .input__label {
    @include side-spacing(margin, right, 8px);
  }

  &__input-message {
    position: relative;
    background-color: $porcelain;
    vertical-align: middle;
    padding: 10px 16px;
    height: 40px;
    border-radius: 3px;
    flex: 1 0 0;
    @include side-spacing(padding, right, 32px);
  }

  &__description {
    @include float(right);
    display: inline-block;

    .list-length {
      @include side-spacing(margin, right, 5px);
    }
  }

  &__clear-input {
    position: absolute;
    top: 0;
    @include side-position(right, 0);
    cursor: pointer;
    width: 30px;
    height: 35px;
    padding: 8px 0;
    font-size: 16px;
    text-align: center;
  }

  &__error-message {
    margin-top: 10px;
    @extend .color-danger;

    .urls-error-list {
      margin-top: 10px;
    }
  }

  &__top-level-domain {
    display: flex;
    align-items: baseline;
    margin: 16px 8px;

    &-message {
      color: $mine-shaft;
    }
  }

  &__warning-icon {
    @include side-spacing(margin, right, 8px);
  }

  &__action-buttons {
    margin-top: 20px;
  }

  .toggle-btn {
    @include text-align(left);
  }

  .input__label {
    font-size: initial;
    line-height: initial;
  }
}
