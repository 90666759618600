@import "../../styles/colors";

.device-actions-modal {
  .items__container {
    div {
      padding: 8px 0;
    }

    .item--disabled {
      color: $gray-chateau;
      pointer-events: none;
    }

    .icon {
      width: 14px;
    }

    :not(.item--disabled) .icon {
      color: $mid-gray;
    }
  }

  .modal__header {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .modal__footer {
    height: 40px;
  }

  .unmodified__btn {
    border: none;
    background-color: transparent;
    color: $mine-shaft;
    width: 100%;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }
}
